import React, { useState } from 'react'
import {InnerData, TLM, VisibleTooltips} from '../utils/type';
import { Handle, Position } from 'reactflow';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import { determineColorNew, handleCopyMneName } from 'utils/function';
import PlotlyChart from './PlotlyChart';

interface ScSketchProp{
  data: {
    name: string;
    value: InnerData;
    select: TLM;
  }
}


const ScSketch: React.FC<ScSketchProp> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips((prevVisibleTooltips) => ({
      ...prevVisibleTooltips,
      [key]: true,
    }));

    setTimeout(() => {
      setVisibleTooltips((prevVisibleTooltips) => ({
        ...prevVisibleTooltips,
        [key]: false,
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops((prevVisibleLimitPops) => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key],
    }));
  };

    const getColor = (name: string) => {
        if (data && data.select && data.select.telemetry){
          if (data.select.telemetry.toLowerCase().includes(name.toLowerCase())) {
            return "green"; // Green for the selected and ON disk
          }
          else{
            return "#404040";
          }
        }else{
          return "#404040";
        }
      }

  return (
    <div className={css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    `}>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{top:220, opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{opacity:0, top:650}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

        <div style={{
            display: 'flex',
            flexDirection: 'column',
        }}>

          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="400px" height="360px" viewBox="-0.5 -0.5 400 360">
            <defs/>
              <g > {/* Rotate the whole SVG transform="rotate(45 150 150)" */}
                <g>
                  <rect x="5" y="50" width="390" height="300" fill="#606060" stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                </g>
                <g>
                  <rect x="5" y="5" width="90" height="90" fill={getColor('MGA')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="10" y="47" fill="white" fontSize="45px">MGA</text>
                  <text x="25" y="82" fill="white" fontSize="45px">Tx</text>
                </g>
                <g>
                  <rect x="305" y="5" width="90" height="90" fill={getColor('MGA')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="310" y="47" fill="white" fontSize="45px">MGA</text>
                  <text x="325" y="82" fill="white" fontSize="45px">Rx</text>
                </g>
                <g>
                  <rect x="35" y="95" width="60" height="60" fill={getColor('lga1')} stroke="rgb(13,39,45)" strokeWidth="5" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="40" y="125" fill="white" fontSize="25px">LGA</text>
                  <text x="40" y="150" fill="white" fontSize="25px">1Tx</text>
                </g>
                <g>
                  <rect x="305" y="95" width="60" height="60" fill={getColor('lga1')} stroke="rgb(13,39,45)" strokeWidth="5" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="310" y="125" fill="white" fontSize="25px">LGA</text>
                  <text x="310" y="150" fill="white" fontSize="25px">1Rx</text>
                </g>
                <g>
                    <circle cx="200" cy="95" r="90" fill={getColor('HGA')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                    <text x="160" y="110" fill="white" fontSize="45px">HGA</text>
                </g>
              
            </g>

            <text x="145" y="290" fill="white" fontSize="45px">+ Z</text>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="400px" height="360px" viewBox="-0.5 -0.5 400 360">
            <defs/>

              <g > {/* Rotate the whole SVG transform="rotate(45 150 150)" */}
              <g>
                    <circle cx="200" cy="95" r="90" fill={getColor('hga')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                </g>

                <g>
                  <rect x="5" y="5" width="90" height="90" fill={getColor('mga')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>

                </g>
                <g>
                  <rect x="305" y="5" width="90" height="90" fill={getColor('mga')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>

                </g>
                <g>
                  <rect x="5" y="50" width="390" height="300" fill="#606060" stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                </g>
                <g>
                  <rect x="35" y="95" width="60" height="60" fill={getColor('lga2')} stroke="rgb(13,39,45)" strokeWidth="5" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="40" y="125" fill="white" fontSize="25px">LGA</text>
                  <text x="40" y="150" fill="white" fontSize="25px">2Tx</text>
                </g>
                <g>
                  <rect x="305" y="95" width="60" height="60" fill={getColor('lga2')} stroke="rgb(13,39,45)" strokeWidth="5" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="310" y="125" fill="white" fontSize="25px">LGA</text>
                  <text x="310" y="150" fill="white" fontSize="25px">2Rx</text>
                </g>

              
            </g>

            <text x="145" y="290" fill="white" fontSize="45px">- Z</text>
            </svg>
                  </div>

        <div className={scheme.svgValueColCon}>
        {data.value &&
          Object.entries(data.value).map(([key, innerData]) => (
            <div className={cx(scheme.svgValueSpanContainer, css`width:350px`)} key={key}>
              <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)} onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && (
                  <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>
                    {innerData.dbData.mne}
                  </span>
                )}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>
                  {innerData.fname}
                </span>
              </span>
              <span
                onClick={() => handleValueChartClick(key)}
                className={cx(
                  scheme.svgValue,
                  css`
                    color: ${innerData.dbData?.live};
                  `,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)]
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && (
                <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>
              )}
              {visibleLimitPops[key] && (
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>


    </div>
  );
}

export default ScSketch;
