import React,{useState} from 'react'
import { Handle, Position } from 'reactflow';
import { determineColorNew} from '../utils/function';
import {TbProps} from '../utils/type';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import * as tbScheme from './TBScheme';
import copy from 'copy-to-clipboard';

type VisibleTooltips = { [key: string]: boolean };

const ESCCommTB: React.FC<TbProps> = ({data}) => {

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});


  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  return (
    <div id={`escCommTB-wrapper `}>
      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left"/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{top:100, opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-3" style={{top:250, opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-4" style={{top:171, opacity:0}}/>

      <Handle type="target" position={Position.Right} id="target-right"/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{top:500, opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-2" style={{top:450, opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-3" style={{top:350, opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-4" style={{top:350, opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-5" style={{top:520, opacity:0}}/>

      <Handle type="target" position={Position.Bottom} id="target-bottom"/>
      <Handle type="target" position={Position.Bottom} id="target-bottom-1" style={{left:400, opacity:0}}/>

      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left"/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{top:200, opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-2" style={{top:450, opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-3" style={{top:350, opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-4" style={{top:450, opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-5" style={{top:645.5, opacity:0}}/>

      <Handle type="source" position={Position.Right} id="source-right"/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{top:400, opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-2" style={{top:250, opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-3" style={{top:250, opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-4" style={{top:210, opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>
      <Handle type="source" position={Position.Bottom} id="source-bottom-1" style={{left:50, opacity: 0}}/>

      <div className={cx(scheme.textboxContainer, tbScheme[data.className as tbScheme.ClassKey])}>

        <p>{data.name}</p>

        <div className={scheme.textboxItems}>
           
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key} >
              <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData?.live}`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ESCCommTB;
