import * as scheme from './Scheme'; // Import your sty
import * as htbScheme from './HTextboxScheme'; // Import your style
import { css, cx } from '@emotion/css';

import React, {useState} from 'react'
import { Handle, Position } from 'reactflow';
import {TbProps} from '../utils/type';
import {determineColorNew} from '../utils/function';

import PlotlyChart from './PlotlyChart';
import copy from 'copy-to-clipboard';

type VisibleTooltips = { [key: string]: boolean };

const HTextBox: React.FC<TbProps> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const powerClass = (data.power && (data.power.telemetry === '1' || data.power.telemetry === 'On')) ? scheme.powerClass : '';

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-4" style={{top:150, opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{left:150, opacity:0}}/>
      
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:50}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <div className={cx(scheme.textboxContainer, htbScheme[data.className as htbScheme.ClassKey], powerClass)}>
        <p>{data.name}</p>
        <div className={scheme.tbHorContainer}>
          {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.tbValueContainer} key={key}>
              <div className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                {key}:
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
              </div>
              <div className={`${scheme.tbValueSpanContainer} nowheel nodrag`} onClick={() => handleValueChartClick(key)}>
                <span 
                  className={cx(
                    scheme.value, 
                    css`color: ${innerData.dbData?.live}`,
                    scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                  )}
                >
                  {innerData.dbData?.telemetry}
                </span>
                {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              </div>
              {visibleLimitPops[key] && 
                <div className={scheme.chartContainer}>
                  <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                  <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                    Close Plot
                  </button>
                </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HTextBox;
