import { useTimeRangeContext } from 'hooks/TimeRangeContext';
import React from 'react';
import { Field } from 'utils/type';

type BarChartProps = {
  data: {
    x1: number;
    x2: number;
    x3: number;
    name: string;
  };
};

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const { gapsData } = useTimeRangeContext();

  console.log('Gaps Data:', gapsData);

  const getGapsData = (gapsData: Field[]) => {
    const startTimeColumn = gapsData.find((column) => column.name === 'startTime');
    const endTimeColumn = gapsData.find((column) => column.name === 'endTime');
    const gapSizeColumn = gapsData.find((column) => column.name === 'gapSize');

    const startTimes = startTimeColumn ? startTimeColumn.values : [];
    const endTimes = endTimeColumn ? endTimeColumn.values : [];
    const gapSizes = gapSizeColumn ? gapSizeColumn.values : [];

    return startTimes.map((startTime: number, index: number) => ({
      start: startTime,
      end: endTimes[index],
      gapSize: gapSizes[index],
    }));
  };

  // Convert gaps into available time periods
  const getAvailablePeriods = (gaps: { start: number; end: number }[]) => {
    if (gaps.length === 0) {
      return [{ start: 0, end: 72 }]; // Full week if no gaps
    }

    // Sort gaps by start time
    const sortedGaps = [...gaps].sort((a, b) => a.start - b.start);
    const availablePeriods = [];
    let currentTime = 0;

    // Process each gap and create available periods
    sortedGaps.forEach(gap => {
      if (gap.start > currentTime) {
        availablePeriods.push({
          start: currentTime,
          end: gap.start
        });
      }
      currentTime = gap.end;
    });

    // Add final period if needed
    if (currentTime < 72) {
      availablePeriods.push({
        start: currentTime,
        end: 72
      });
    }

    return availablePeriods;
  };

  const gaps = getGapsData(gapsData);

  const totalWidth = 1500;
  const totalHeight = 200;
  const scale = totalWidth / 72; // 168 hours (7 days) scaling factor

  // Define segments with labels (reversed order)
  const segments = [
    { start: 48, end: 72, label: '48-72h', color: 'rgb(225,225,247)' },
    { start: 24, end: 48, label: '24-48h', color: '#d8d3f2' },
    { start: 12, end: 24, label: '12-24h', color: 'rgb(169,169,249)' },
    { start: 0, end: 12, label: '0-12h', color: 'rgb(115,119,247)' },
  ];

  // Function to flip the x-coordinate
  const flipX = (hour: number) => totalWidth - (hour * scale);

  const convertToHours = (timestamp: number) => {
    const currentTime = Date.now() / 1000;
    const hoursSinceNow = (currentTime - timestamp) / 3600;

    console.log('Hours since now:', hoursSinceNow, currentTime, timestamp);
    
    // Ensure the time is within the last 72 hours
    if (hoursSinceNow < 0) {
      return 0; // Future times are clamped to 0
    }
    if (hoursSinceNow > 72) {
      return 72; // Times older than 72 hours are clamped to 72
    }
    
    // Convert to hours from now (0-72 range, where 0 is now and 72 is 72 hours ago)
    return 72 - hoursSinceNow; // Flip the range so newer times are on the right
  };

  const availablePeriods = getAvailablePeriods(gaps.map(gap => ({
    start: convertToHours(gap.start),
    end: convertToHours(gap.end),
  })));


  console.log('Available Periods:', availablePeriods);


  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1600"
        height="400"
        version="1.1"
        viewBox="0 0 1600 400"
      >
        {/* Background */}
        <rect
          x="75"
          y="30"
          width={totalWidth}
          height="160"
          fill="rgb(38,38,38)"
          stroke="black"
          strokeWidth="8"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
        {/* Segments */}
        {segments.map((segment, index) => (
          <rect
            key={index}
            x={75 + flipX(segment.end)}
            y="30"
            width={(segment.end - segment.start) * scale}
            height="160"
            fill={segment.color}
          />
        ))}
        {/* Available Time Periods */}
        {availablePeriods.map((period, index) => (
          console.log('period', period,75 + flipX(period.end),(period.end - period.start) * scale),
          <rect
            key={`period-${index}`}
            x={75 + flipX(period.end)}
            y="80"
            width={(period.end - period.start) * scale}
            height="60" 
            fill="gray"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeMiterlimit="10"
          />
        ))}
        {/* Axis Labels */}
        {segments.map((segment, index) => {
          const labelX =
            75 + flipX(segment.start) - ((segment.end - segment.start) * scale / 2);

          return (
            <g key={`label-${index}`}>
              {/* Tick */}
              <line
                x1={labelX}
                y1={totalHeight + 35}
                x2={labelX}
                y2={totalHeight}
                stroke="#FFFFFF" 
                strokeWidth="8"
              />
              {/* Label */}
              <text
                x={labelX}
                y={totalHeight + 85}
                fill="white"
                fontSize="30"
                fontFamily="Arial"
                textAnchor="middle"
              >
                {segment.label}
              </text>
            </g>
          );
        })}

        {/* Gray Inner Bar */}
        {/* <rect
          x="75"
          y="80"
          width={totalWidth}
          height="60"
          fill="gray"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeMiterlimit="10"
        /> */}
      </svg>
    </div>
  );
};

export default BarChart;