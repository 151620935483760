// utils/dataProcessing.ts

import { DataObject, Field, FlattenedItem, ProcessedData, TelemetryData, TelemetryDictionary, redisDataType } from 'utils/type';
import { decodeFunc, determineLimit, lookUpName, nameToMne, variableName } from 'utils/function';
import { getTemplateSrv } from '@grafana/runtime';

function isWithinDifference(unixTimestamp: number) {

    // Convert the Unix timestamp to a JavaScript Date object
    const timestampDate = new Date(unixTimestamp * 1000);
  
    // Get the current time
    const currentDate = new Date();
  
    // Calculate the difference in seconds
    const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;
  
    // Compare and return
    if (timeDifference <= 30) {
        return "rgb(72, 200, 44)";
    } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours 
        return "#CA51EC";
    } else {
        return "rgb(68, 169, 241)"; //blue -- stale data
    }
  }

  function convertTimestampToPST(timestamp: number) {
    // Create a new Date object using the timestamp (in milliseconds)
    const date = new Date(timestamp * 1000);
  
    // To convert to PST, get the UTC time and subtract 8 hours for PST
    // Note: JavaScript Date object functions account for Daylight Saving Time changes
    const pstDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours() - 8, date.getUTCMinutes(), date.getUTCSeconds());
  
    // Format the date and time in a readable format
    const formattedDate = pstDate.getFullYear() + '-' +
      ('0' + (pstDate.getMonth() + 1)).slice(-2) + '-' +
      ('0' + pstDate.getDate()).slice(-2) + ' ' +
      ('0' + pstDate.getHours()).slice(-2) + ':' +
      ('0' + pstDate.getMinutes()).slice(-2) + ':' +
      ('0' + pstDate.getSeconds()).slice(-2);
  
    return formattedDate;
  }

export const getDataFromMySQL = (mne: string, dbData: Field[]) => {
  const mneColumn = dbData.find(column => column.name === 'mne');
  if (!mneColumn) {
    return { limit: null, cnvValue: null, unit: null, live: '', mne: mne };
  }

  const mneIndex = mneColumn.values.indexOf(mne);
  if (mneIndex === -1) {
    return { limit: null, cnvValue: null, unit: null, live: '', mne: mne };
  }

  const limitColumn = dbData.find(column => column.name === 'limit');
  const cnvValueColumn = dbData.find(column => column.name === 'cnvValue');
  const unitColumn = dbData.find(column => column.name === 'units');
  const timeColumn = dbData.find(column => column.name === 'tInsert');

  const limit = limitColumn ? limitColumn.values[mneIndex] : null;
  let cnvValue = cnvValueColumn ? cnvValueColumn.values[mneIndex] : null;
  let unit = unitColumn ? unitColumn.values[mneIndex] : null;
  let live = timeColumn ? isWithinDifference(timeColumn.values[mneIndex]) : '';

  // Unit formatting logic
  if (unit === 'C' || unit === 'degC' || unit === 'Celsius') {
    unit = '\u00B0C';
  } else if (unit === 'packets') {
    unit = 'P';
  } else if (unit === 'sec') {
    unit = 'S';
  } else if (['None', 'na', 'enum', 'N/A'].includes(unit)) {
    unit = null;
  } else if (unit === 'bool') {
    unit = 'B';
  } else if (unit === 'count' || unit === 'Counts' || unit === 'counts' || unit === 'Counter') {
    unit = 'Cnt';
  } else if (unit === 'rad/s') {
    unit = 'r/s';
  }

// Value formatting logic
if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
  const numValue = parseFloat(cnvValue);
  const [integerPart] = numValue.toString().split('.');

  if (integerPart.length > 7) {
    cnvValue = numValue.toExponential(2);
  } else {
    // Check if the original value had a decimal point
    if (Number.isInteger(numValue) && !cnvValue.includes('.')) {
      cnvValue = numValue.toString();
    } else {
      cnvValue = numValue.toFixed(2);
    }
  }
}


  return { limit, cnvValue, unit, live, mne };
};

const processRedisData = (dbData: Field[], limitData: Field[]) => {

    const telemetryDict: Record<string, TelemetryData> = {};

    const valueColumn = dbData.find(column => column.name === "value");
    const limitMneColumn = limitData.find(column => column.name === "mne");
    const limitYLColumn = limitData.find(column => column.name === "yl");
    const limitYHColumn = limitData.find(column => column.name === "yh");
    const limitRLColumn = limitData.find(column => column.name === "rl");
    const limitRHColumn = limitData.find(column => column.name === "rh");
    
    if (!valueColumn) {
      return {}; // mne column not found
    }

    const valueValue = valueColumn && valueColumn.values ? valueColumn.values : [];

    valueValue.map(value => {
      const decodeValue = decodeFunc(value) as redisDataType

      if (!decodeValue || typeof decodeValue !== "object" || !decodeValue.mne) {
        console.warn("Invalid or missing mne in decoded value:", decodeValue);
        return; // Skip this iteration
      }

      const mne = decodeValue.mne;
      const limitMneIndex = limitMneColumn && limitMneColumn.values.indexOf(mne);

      
      let limitValue;
      let cnvValue = decodeValue.cnv;

      if (limitMneIndex === -1 || !limitMneIndex) {
        limitValue = ''
      }
      else {

        const ylValue = limitYLColumn ? limitYLColumn.values[limitMneIndex] : null;
        const yHValue = limitYHColumn ? limitYHColumn.values[limitMneIndex] : null;
        const rlValue = limitRLColumn ? limitRLColumn.values[limitMneIndex] : null;
        const rHValue = limitRHColumn ? limitRHColumn.values[limitMneIndex] : null;

        limitValue = determineLimit(cnvValue, {
          yl: ylValue,
          yh: yHValue,
          rl: rlValue,
          rh: rHValue
        })
      }

      const valueName = lookUpName(mne);

      // Special handling for 'WFI HV' and 'NFI HV'
      if (mne === 'tcs_spoc_rtd_n2_bottle_probe_1_t' || mne === 'tcs_spoc_rtd_n2_bottle_probe_2_t'|| mne === 'tcs_spoc_rtd_n2_bottle_probe_3_t'|| mne === 'tcs_spoc_rtd_n2_bottle_probe_4_t') {
        cnvValue = parseFloat(cnvValue).toExponential(2);
      } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
        // Check if cnvValue is a number and convert/round it if s
        const numValue = parseFloat(cnvValue);
        const [integerPart, decimalPart] = numValue.toString().split('.');

        // Convert to exponential form if the integer part is too long
          if (integerPart.length > 7) {
        cnvValue = numValue.toExponential(2);
          } else if (decimalPart) {
            // It's a float, parse and keep 2 decimal places
            cnvValue = numValue.toFixed(2);
          }
      }

      telemetryDict[valueName] = {
        limit: limitValue,
        mne: mne,
        telemetry: cnvValue,
        live: isWithinDifference(decodeValue.t_insert),
        spacecraft: decodeValue.facility,
        unit: ""
      };
    });

    return telemetryDict;
  };

  const editRedisDataLimit = (data: TelemetryDictionary, limitData: Field[]) => {
    const limitMneColumn = limitData.find(column => column.name === "mne");
    const limitYLColumn = limitData.find(column => column.name === "yl");
    const limitYHColumn = limitData.find(column => column.name === "yh");
    const limitRLColumn = limitData.find(column => column.name === "rl");
    const limitRHColumn = limitData.find(column => column.name === "rh");
    const limitSwitchMneColumn = limitData.find(column => column.name === "switchMne");
    const limitSwitchLowerColumn = limitData.find(column => column.name === "switchLower");
    const limitSwitchUpperColumn = limitData.find(column => column.name === "switchUpper");

    const limitSwitchMneValue = limitSwitchMneColumn && limitSwitchMneColumn.values ? limitSwitchMneColumn.values : [];

    // if there is a switchMne coumn, then we need to use this set of limit. Therefore we will replace the old limit value
    // with the new limit value 
    limitSwitchMneValue.map(switchMneValue => {
      
      const limitMneIndex = limitSwitchMneColumn && limitSwitchMneColumn.values.indexOf(switchMneValue);

      if (limitMneIndex) {
        
        const limitSwitchLowerValue = limitSwitchLowerColumn ? limitSwitchLowerColumn.values[limitMneIndex] : "";
        const limitSwitchUpperValue = limitSwitchUpperColumn ? limitSwitchUpperColumn.values[limitMneIndex] : "";
        const limitMneValue = limitMneColumn ? limitMneColumn.values[limitMneIndex] : null;
        const limitYLValue = limitYLColumn ? limitYLColumn.values[limitMneIndex] : null
        const limitYHValue = limitYHColumn ? limitYHColumn.values[limitMneIndex] : null
        const limitRLValue = limitRLColumn ? limitRLColumn.values[limitMneIndex] : null
        const limitRHValue = limitRHColumn ? limitRHColumn.values[limitMneIndex] : null;

        const switchMneName = lookUpName(switchMneValue);
        const mneName = lookUpName(limitMneValue);

        const switchMneCnv = data[switchMneName].telemetry;
        const mneValue = data[mneName].telemetry;

        if (limitSwitchLowerValue <= switchMneCnv <= limitSwitchUpperValue){

          const limit = determineLimit(mneValue, {
            yl: limitYLValue,
            yh: limitYHValue,
            rl: limitRLValue,
            rh: limitRHValue
          });

          data[mneName].limit = limit;

        }

      }

    });

    return data;
  };

export const getGroundData = (groundData: Field[]) => {
  const stationColumn = groundData.find(column => column.name === 'station');
  const scColumn = groundData.find(column => column.name === 'scName');
  const tAosColumn = groundData.find(column => column.name === 'tAos');
  const tLosColumn = groundData.find(column => column.name === 'tLos');
  const trCodeColumn = groundData.find(column => column.name === 'trCode');

  const station = stationColumn ? stationColumn.values[0] : null;
  const scName = scColumn ? scColumn.values[0] : null;
  const tAos = tAosColumn ? tAosColumn.values[0] : null;
  const tLos = tLosColumn ? tLosColumn.values[0] : null;
  const trCode = trCodeColumn ? trCodeColumn.values[0] : null;

  return {
    SC: scName,
    GS: station,
    AOS: convertTimestampToPST(tAos),
    LOS: convertTimestampToPST(tLos),
    Tr: trCode,
  };
};

export const createTeleFromMySQL = (dbData: Field[]) => {
  const telemetryDict: Record<string, TelemetryData> = {};

  Object.entries(nameToMne).forEach(([name, mne]) => {
    let newMne = mne + '_agg';
    let data = getDataFromMySQL(newMne.toLowerCase(), dbData);
    const source = getTemplateSrv().replace(`\${${variableName}}`);

    if (!data.cnvValue) {
      newMne = mne + '_sh';
      data = getDataFromMySQL(newMne.toLowerCase(), dbData);
    }

    if (!data.cnvValue) {
      newMne = mne;
      data = getDataFromMySQL(newMne.toLowerCase(), dbData);
    }

    telemetryDict[name] = {
      limit: data.limit,
      mne: data.mne,
      telemetry: data.cnvValue,
      unit: data.unit,
      live: data.live,
      spacecraft: source ? source : '',
    };
  });

  return telemetryDict;
};

export const createTelemetryDictionary = (dbData: Field[], limitData: Field[]) => {
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const valueColumn = dbData.find(column => column.name === "value");

    if (valueColumn) {
      const redisData = processRedisData(dbData, limitData);
      const redisDataWithLimit = editRedisDataLimit(redisData, limitData);
      return redisDataWithLimit;
    } else if (cnvValueColumn) {
      const sqlData = createTeleFromMySQL(dbData);
      return sqlData;
    } else {
      return {};
    }
  };


export function processInfluxRawData(dataArray: DataObject[]): ProcessedData {
    const result: ProcessedData = {};
  
    dataArray.forEach(dataObject => {
      const timeField = dataObject.fields.find(field => field.name.toLowerCase() === "_time");
      const cnvValueField = dataObject.fields.find(field => field.name.toLowerCase() === "cnv_value");
      const tpktField = dataObject.fields.find(field => field.name.toLowerCase() === "t_pkt");
      const tInsertField = dataObject.fields.find(field => field.name.toLowerCase() === "t_insert");

      if (timeField && cnvValueField && tpktField && tInsertField) {

        timeField.values.forEach((timeValue, index) => {
          const cnvValue =  cnvValueField.values[index] === null ? "" : cnvValueField.values[index].toFixed(2);
  
          const mne = cnvValueField && cnvValueField.labels ? cnvValueField.labels.mne : "";
  
          if (!result[timeValue]) {
            result[timeValue] = [];
          }

          const name = lookUpName(mne);
  
          result[timeValue].push({ cnvValue, mne, name });
        });
      }
    });
  
    return result;
}

export function flattenInfluxData(processedData: ProcessedData): FlattenedItem[] {
  // Create a Map to track seen mne values
  const seenMne = new Map();
  const flattenedArray: FlattenedItem[] = [];
  
  // Get all the timestamp arrays and sort them to ensure we process earlier timestamps first
  const timestamps = Object.keys(processedData).sort();
  
  // Iterate through each timestamp
  timestamps.forEach(timestamp => {
    const dataArray = processedData[timestamp];
    
    // Iterate through each object in the array
    dataArray.forEach(item => {
      // If we haven't seen this mne before, add it to our results
      if (!seenMne.has(item.mne)) {
        seenMne.set(item.mne, true);
        flattenedArray.push({
          ...item,
          timestamp // Optionally keep the timestamp if needed
        });
      }
      // If we have seen this mne before, skip it
    });
  });
  
  return flattenedArray;
}

