import { decode } from "@msgpack/msgpack";
import { getTemplateSrv } from "@grafana/runtime";
import copy from 'copy-to-clipboard';
import { LimitProp } from "./type";

export const nameToMne = {

  /*CDH */
  "LGA Selected": "cdh_ant_mgr_lga_pair_select_agg", /*1 or 2 for which LGA should be used*/
  "LGA Limit": "cdh_ant_mgr_past_lga_limit", /*State of the LGA Operational Limit. */
  "Ant State": "cdh_ant_mgr_state_machine_state_agg", /* Current state of the antenna selection process */

  "Cmd Tlm Accepted": "cdh_cmd_tlm_cmdacccnt", /* Cmd & Tlm Command Accepted Count */
  "Cmd Tlm Rejected": "cdh_cmd_tlm_cmdrejcnt", /* Cmd & Tlm Command Rejected Count */
  "CDH Accpeted": "cdh_cmdacccnt", /* Command and Data Handling Subsystem Command Accepted Count */
  "CDH Rejected": "cdh_cmdrejcnt", /* Command and Data Handling Subsystem Command Rejected Count */
  
  "SPOC Read Error": "cdh_param_svc_spoc_read_errors", /* The number of read errors that have occured */
  "SPOC Write Error": "cdh_param_svc_spoc_write_errors", /* The number of write errors that have occured */
  "Supvr Read Error": "cdh_param_svc_supervisor_read_errors", /* The number of read errors that have occured */
  "Supvr Write Error": "cdh_param_svc_supervisor_write_errors", /* The number of write errors that have occured */

  "ARmed Downlink Data SOH": "cdh_spoc_iob_spw_armed_downlink_data_soh", /* Shows if the armed downlink frame is SoH data */
  "Armed Downlink Packet": "cdh_spoc_iob_spw_armed_downlink_packet", /* Number of successfully armed downlink packet */
  "SOH Data Size": "cdh_spoc_iob_spw_collected_soh_data_size", /* Size of SoH data waiting in the queue to be armed for tx */
  "Discarded SOH Data": "cdh_spoc_iob_spw_discarded_soh_data", /* Number of discarded SoH packet in the queue */
  "Downlink Data Quene Size": "cdh_spoc_iob_spw_downlink_data_queue_size", /* Number of queued downlink data */
  "Fail CRC": "cdh_spoc_iob_spw_error_calculate_crc", /* Number times failed to calculate CRC */
  "Failed Create Downlink Packet": "cdh_spoc_iob_spw_failed_to_create_downlink_packet", /* Number of times failed to create downlink packet */
  "Failed Queue Downlink Packet": "cdh_spoc_iob_spw_failed_to_queue_downlink_packet", /* Number of times failed to queue downlink packet */
  "Failed Serizlize SOH": "cdh_spoc_iob_spw_failed_to_serialize_soh_data", /* Number of times failed to serialize SoH data */
  "Processed Tx Tlm Cmd": "cdh_spoc_iob_spw_processed_tx_telemetry_command", /* Number of Tx Telemetry command processed */
  "Quened Downlink Packet": "cdh_spoc_iob_spw_queued_downlink_packet", /* Number of successfully queued downlink packet */

  "Supvr OS Running": "cdh_supervisor_os_running", /* > 0 indicates OS is running */
  "Supvr Uptime": "cdh_supervisor_uptime_agg", /* Time CPU running since boot */
  "Supvr Boot Ctn": "cdh_supervisor_boot_count_agg", /* number of times processor has been booted */
  "Supvr Boot Chip": "cdh_supervisor_boot_chip_agg", /* Reason for last boot */
  "Supvr Boot Index": "cdh_supervisor_boot_index_agg", /*  */
  "Supvr pa3 last Boot": "cdh_supervisor_pa_3_last_boot_source", /* Reason for last boot */
  "Supvr pa3 next Boot": "cdh_supervisor_pa_3_next_boot_source", /*  */
  "Supvr CD Time": "cdh_gnd_quiet_wd_supervisor_countdown_time_agg", /*  */
  "Supvr Monitor State": "cdh_supervisor_monitor_state_agg", /*  */
  "Supvr Received rejected time pkt": "cdh_supervisor_time_sync_rejected_time_packet_count", /* Number of times we have received a time packet we reject */
  "Supvr Nand Read Error": "cdh_supervisor_nand_read_errors", /* Number of NAND read errors */
  "Supvr Nand Write Error": "cdh_supervisor_nand_write_errors", /* Number of NAND write errors */
  "Supvr Accepted Swap Ctn": "cdh_firecode_supervisor_accepted_swap_count", /* number of times we have swapped. Tracked persistently */
  "Supvr Cool Down time": "cdh_firecode_supervisor_cool_down_time", /* Minimum time between CCD pulses */
  "Supvr Rejected Swap Ctn": "cdh_firecode_supervisor_rejected_swap_count", /* Number of times swap rejected due to cooldown */
  "Supvr Last Pulse Source": "cdh_firecode_supervisor_last_pulse_source", /* source of last received pulse */

  "Link Status": "comms_proasic_spw_active", /* If link status is active */
  "Coh State": "comms_spoc_iob_frlite_coherency_state", /* Coherent downlink state. */
  "Mission Mode": "comms_spoc_iob_frlite_mission_mode_sel", /* The current selected mission mode. */
  "100hz cnt": "comms_spoc_iob_frlite_radio100_hz_cnt", /* The radio 100 Hz counter. */
  "Baseband Enabled": "comms_spoc_iob_frlite_rx_bb_ena", /* The RX baseband enable signal state indicator. */
  "Carrier Amplitude": "RIM_FRLITE_RX_CARRIER_AMPLITUDE", /* The amplitude of power int eh carrier tracking. */
  "AGC Level": "RIM_FRLITE_RX_AGC_LEVEL", /* The RX AGC level. */
  "Carrier Lock": "RIM_FRLITE_RX_CARRIER_LOCK_agg", /* The carrier lock status. */
  "Carrier Spe": "rim_frlite_rx_carrier_spe_agg", /* Carrier SPE. */
  "Carrier Amp": "rim_frlite_rx_carrier_amplitude_agg", /* The RX carrier tracking. */
  "SubCarrier Lock": "rim_frlite_rx_subcarrier_lock_agg_7606", /* The carrier lock status. */
  "SubCarrier Spe": "rim_frlite_rx_subcarrier_spe_agg_7606", /* Carrier SPE. */
  "SubCarrier Amp": "rim_frlite_rx_subcarrier_amplitude_agg_7606", /* The RX carrier tracking. */
  "Rx Sym Rate H": "RIM_FRLITE_RX_SYM_RATE_H", /* . */
  "Rx Sym Rate L": "RIM_FRLITE_RX_SYM_RATE_L", /* . */
  "Carrier Loop": "comms_spoc_iob_frlite_rx_carrier_loop_sel", /* The RX carrier loop selection. */
  "Coh Enable": "comms_spoc_iob_frlite_rx_coherency_ena", /* Coherent downlink enable status. */

  "Tx Ex Ena": "RIM_FRLITE_EX_ENA", /*  */
  "Tx Ex Rng Ena": "RIM_FRLITE_EX_RNG_ENA", /*  */
  "Tx Ex SubC Freq Sel": "RIM_FRLITE_EX_SUBC_FREQ_SEL", /*  */
  "Tx Ex Sym Rate": "RIM_FRLITE_EX_SYM_RATE", /*  */
  "Tx Frames Sent": "RIM_FRLITE_FRAMES_SENT", /*  */
  "Tx Watchdog count": "RIM_STATE_TX_WATCHDOG_COUNT_SECS", /*  */

  /* SW Version */
  "Board ID": "apid_0x109.mne.board_id", /*Board identifier.*/
  "FSW major": "apid_0x109.mne.fsw_major", /*FSW major version. FSW version adheres to Semantic Versioning (semver.org). Major number is incremented when new backwards incompatible changes are added to the FSW.*/
  "FSW minor": "apid_0x109.mne.fsw_minor", /*FSW minor version. FSW version adheres to Semantic Versioning (semver.org). Minor number is incremented when new backwards compatible features are added to the FSW*/
  "FSW Patch": "apid_0x109.mne.fsw_patch", /*FSW patch version. FSW version adheres to Semantic Versioning (semver.org). Patch number is incremented when new backwards compatible bug fixes are added to the FSW*/

  "SPOC A Voltage":"eps_supervisor_power_rail_spoca_vtlm_agg" , /* */
  "SPOC B Voltage":"eps_supervisor_power_rail_spocb_vtlm_agg" , /* */
  "Spoc Uptime":"cdh_spoc_uptime_agg" , /* */
  "Spoc Monitor State":"cdh_spoc_monitor_state_agg" , /* */
  "Spoc Monitor Tripped":"cdh_spoc_monitor_tripped_channel_agg" , /* */
  "Spoc Switchover Reason":"CDH_SPOC_CONTROL_SWITCHOVER_REASON" , /* */
  "Spoc Csac En":"eps_spoc_dio_csac_en_agg" , /* */

  "Transponder Mission Mode":"comms_spoc_iob_frlite_mission_mode_sel", /* The current selected mission mode. */
  "Rx VC ID":"cdh_spoc_iob_spw_active_rx_vcid", /* Active Rx Virtual Channel ID */
  "TX VC ID":"cdh_spoc_iob_spw_active_tx_vcid", /* Active Tx Virtual Channel ID */
  "Encoder Sel":"comms_spoc_iob_frlite_encc_encoding_sel", /* the encoder encoding settings. As defined in command ENCC_ENCODING_SEL */
  "Tx BB Ena":"comms_spoc_iob_frlite_tx_bb_ena", /* The ModC baseband setting. */

  "SP2T A Power":"eps_supervisor_dio_sp2t_radio_a_select_pwr", /* */
  "SP2T B Power":"eps_supervisor_dio_sp2t_radio_b_select_pwr", /* */
  "SP2T Rx A Ind":"eps_supervisor_dio_sp2t_rx_radio_a_ind_agg", /* */
  "SP2T Rx B Ind":"eps_supervisor_dio_sp2t_rx_radio_b_ind", /* */
  "SP2T Tx A Ind":"eps_supervisor_dio_sp2t_tx_radio_a_ind", /* */
  "SP2T Tx B Ind":"eps_supervisor_dio_sp2t_tx_radio_b_ind_agg", /* */

  "SP4T HGA Power":"eps_supervisor_dio_sp4t_hga_select_pwr", /* */
  "SP4T LGA1 Power":"eps_supervisor_dio_sp4t_lga1_select_pwr", /* */
  "SP4T LGA2 Power":"eps_supervisor_dio_sp4t_lga2_select_pwr", /* */
  "SP4T MGA Power":"eps_supervisor_dio_sp4t_mga_select_pwr", /* */
  "SP4T Reset Power":"eps_supervisor_dio_sp4t_reset_pwr", /* */
  "SP4T Rx HGA Ind":"eps_supervisor_dio_sp4t_rx_hga_ind", /* */
  "SP4T Rx LGA1 Ind":"eps_supervisor_dio_sp4t_rx_lga1_ind", /* */
  "SP4T Rx LGA2 Ind":"eps_supervisor_dio_sp4t_rx_lga2_ind", /* */
  "SP4T Rx MGA Ind":"eps_supervisor_dio_sp4t_rx_mga_ind", /* */
  "SP4T Tx HGA Ind":"eps_supervisor_dio_sp4t_tx_hga_ind", /* */
  "SP4T Tx LGA1 Ind":"eps_supervisor_dio_sp4t_tx_lga1_ind", /* */
  "SP4T Tx LGA2 Ind":"eps_supervisor_dio_sp4t_tx_lga2_ind", /* */
  "SP4T Tx MGA Ind":"eps_supervisor_dio_sp4t_tx_mga_ind", /* */

  "Mission Mode Custom": "RIM_FRLITE_MISSION_MODE_CUSTOM", /* */
  "Mission Mode Sel": "RIM_FRLITE_MISSION_MODE_SEL", /* */
  "Radio Cmds Received":"rim_frlite_cmds_received_agg", /* */
  "Radio Frames Sent":"rim_frlite_frames_sent_agg", /* */
  "Radio Ranging En":"rim_frlite_ex_rng_ena_agg", /* */
  "Radio Tx WD Count":"rim_state_tx_watchdog_count_secs_agg", /* */

  "HPA Enable":"eps_supervisor_dio_hpa_en", /* */
  "HPA OCP BYP":"eps_supervisor_dio_hpa_ocp_byp", /* */
  "HPA HTR Enable":"eps_supervisor_dio_htr_hpa_en_agg", /* */
  "HPA Temp Raw":"tcs_supervisor_hpa_t_raw_agg", /* */
  "HPA Err Counter":"cdh_ant_mgr_enable_hpa_error_counter_agg", /* */
  "FRLITE Decc Frame Bad Count":"RIM_FRLITE_DSP_DECC_FRAME_BAD_CNT", /* */
  "FRLITE Decc CCD Bad Count":"RIM_FRLITE_DECC_CCD_BAD_CNT", /* */
  "FRLITE RF MCU Err Cnt":"RIM_FRLITE_RF_MCU_ERR_CNT", /* */

  "LNA LGA1 5v":"eps_supervisor_dio_lna_lga1_5v_pwr", /* */
  "LNA LGA2 5v":"eps_supervisor_dio_lna_lga2_5v_pwr", /* */
  "LNA MGA 5v":"eps_supervisor_dio_lna_mga_5v_pwr", /* */
  "LNA OCP BYP":"eps_supervisor_dio_lna_ocp_byp", /* */

  "Vehicle Mode Agg":"state_spinand0_last_cmd_vehicle_mode_agg",
  "Q7 Uptime":"cdh_version_fc_uptime_agg",
  "Max FSW Uptime":"cdh_version_uptime_agg",
  "Q7 Boot Count":"cdh_version_fc_boot_count_agg",

  "TIME_CSAC_Tod":"cdh_time_csac_tod", /* */
  "Rim Frlite Rx Nb Pwr":"RIM_FRLITE_RX_NB_PWR", /* */
  "Spvr Time Sync Curr Time":"CDH_SUPERVISOR_TIME_SYNC_CURRENT_TIME", /* */

  "Beacon Flag":"CDH_SPOC_CONTROL_BEACON_MODE_FLAG", /* */
  "Spoc Swap Inhibit":"CDH_SPOC_CONTROL_SPOC_SWAP_INHIBIT_FLAG", /* */

}

export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){
  if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
    return 'red-limit';
  }
  else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
      return 'yellow-limit';
  }else{
    return 'gray-limit';
  }
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value.toLowerCase() === mne.toLowerCase()) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export const decodeFunc = (data: string) => {

  if (!data || data === "") {
    return null;
  }

  const binaryString = window.atob(data);
        
  // Convert binary string to Uint8Array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  
  // Decode the MessagePack data
  const decodedObject = decode(bytes);
  
  return decodedObject;
}

export type redisDataType = {
  apid: number;
  cnv: string;
  facility: string;
  is_pseudo: boolean;
  mne: string;
  raw: number;
  scid: number;
  t_insert: number;
  t_pkt: number;
  vcid: number;
};

const variableName = 'ScName'; // Replace with your Grafana variable
export const source = getTemplateSrv().replace(`\${${variableName}}`);

export const handleCopyMneName = (mne: string) => {
  copy(mne);
};

export function determineLimit(stringValue: string, limit: LimitProp){

  const value = parseFloat(stringValue);

  if (limit.rl !== null && value < limit.rl){
    return 'rl';
  }else if (limit.rh !== null && value > limit.rh){
    return 'rh';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'rl';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'rh';
  }
  else {
    return "";
  }
}
