import React from 'react';
import { Handle, Position } from 'reactflow';
import { css } from '@emotion/css';

interface ESCCommPanelProp {
  data: {
    source: string;
  };
}

const ESCCommPanel: React.FC<ESCCommPanelProp> = ({data}) => {
  let color;
  if (data.source.includes('escg')) {
    color = 'gold';
  } else if (data.source.includes('escb')) {
    color = 'rgb(13, 57, 152)';
  } else {
    color = 'gray';
  }

  const containerStyle = css`
    width: 4150px;
    height: 2225px;
    border: 6px solid ${color};
    background-color: rgb(38, 38, 38);
  `;

  return (
    <div className={containerStyle}>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity: 0}} />
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity: 0}} />
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity: 0}} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity: 0}} />
    </div>
  );
}

export default ESCCommPanel;
