import React, {useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, { Node, ReactFlowInstance, ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { Field, FlattenedItem, LocationData, ProcessedData, TLM, TelemetryDictionary } from '../utils/type';
import ESCCommPanel from '../module/ESCCommPanel';
import CDH from '../module/CDH';
import ESCCommTB from './ESCCommTB';
import Ground from 'module/Ground';
import Tele from 'module/Tele';
import Antennas from './Antennas';
import MOC from 'module/MOC';
import CustomEdge from './CustomEdge';
import SBandTrans from 'components/SBandTrans';
import VaryAntennas from './VaryAntennas';
import SpacecraftModel from './SpacecraftModel';
import Chart from './PlotlyChart'
import BarChart from './Bar';
import { css } from '@emotion/css';
import VModeBox from './VModeBox';
import HTextBox from './HTextbox';
import ScSketch from './ScSketch';
import PassIndicator from './PassIndicator';
import { useDrawer } from 'hooks/DrawerContext';
import { createTelemetryDictionary, getGroundData } from 'utils/dataProcessFunc';
import { createNodes } from 'utils/createNode';
import DrawerComponent from './Drawer';
import { useTimeRangeContext } from 'hooks/TimeRangeContext';

const reactAppWrapper = css` 

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-escCommPanel{
  z-index: 5 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
`;

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  groundData: Field[];
  source: string;
  locationData: LocationData;
  influxData: ProcessedData | FlattenedItem[];
  limitData: Field[];
  // ... other props if there are ay
};

const nodeTypes = {spacecraftModel: SpacecraftModel,passIndicator:PassIndicator,htextbox:HTextBox,scSketch:ScSketch,bar: BarChart,varyAntennas: VaryAntennas,vmodeBox:VModeBox,chart: Chart, ground: Ground,sbtrans:SBandTrans,escCommPanel: ESCCommPanel,antennas:Antennas, tele:Tele,cdh:CDH, escCommTB: ESCCommTB, moc:MOC}
const edgeTypes = {customEdge: CustomEdge}

const ESCCommApp: React.FC<AppProps> = ({dbData, groundData, source, locationData, width, height, influxData, limitData}) => {

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  const { isDrawerOpen, onClose } = useDrawer();
  const playbackRef = useRef({ isProcessing: false });

  const groundList = useMemo(() => {
    return getGroundData(groundData);
  }, [groundData]);

  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance) {
      reactFlowInstance.fitView();
    }
  }
    , [width, height, reactFlowInstance]);

    const telemetryDictionary = useMemo(() => {
      return createTelemetryDictionary(dbData, limitData);
    }, [dbData, limitData]);
  
    const initialNodes = useMemo(() => {
      return createNodes(telemetryDictionary, pbTimeRef.current, pbLoopRef.current, groundList, locationData);
    }, [telemetryDictionary]);
   
    const [nodes, setNodes] = useState<Node[]>(initialNodes);
  
    const keyMnePairs = useMemo(() => {
      const pairs: Record<string, string> = {};
      
      nodes.forEach(node => {
        if (node.data.value) {
          Object.entries(node.data.value).forEach(([key, data]) => {
            // @ts-ignore
            pairs[key] = data.dbData?.mne || '';
          });
        }
      });
      
      return pairs;
    }, [nodes]);

    // Move data processing logic outside of render cycle
  const processDataRef = useRef(async (aggregatedData: TelemetryDictionary, totalLoops: number, influxDataInput: ProcessedData) => {
    if (playbackRef.current.isProcessing) return;
    playbackRef.current.isProcessing = true;

    try {
      for (let i = 0; i < totalLoops; i++) {
        if (!guiValues.current.pbisInPlayBack) {
          break;
        }

        const key = Object.keys(influxDataInput)[i];
        const selectedData = influxDataInput[key];
        pbTimeRef.current = key;
        const newValue = (i + 1) / totalLoops;
        pbLoopRef.current = newValue;

        // Update aggregatedData with new values
        selectedData.forEach(item => {
          if (aggregatedData[item.name]) {
            if (item.cnvValue !== '') {
              aggregatedData[item.name].telemetry = item.cnvValue;
            }
          } else {
            aggregatedData[item.name] = {
              telemetry: item.cnvValue,
              mne: item.mne,
              limit: '',
              unit: '',
              spacecraft: '',
              live: 'rgb(68, 169, 241)',
            };
          }
        });

        // Create nodes outside of render cycle
        const newNodes = createNodes(
          aggregatedData,
          pbTimeRef.current,
          pbLoopRef.current,
          groundList,
          locationData,
        );

        // Batch state update
        setNodes(newNodes);

        await new Promise(resolve => setTimeout(resolve, 2000));
      }
    } finally {
      playbackRef.current.isProcessing = false;
    }
  });


    useEffect(() => {

      if (guiValues.current.pbisInPlayBack && !guiValues.current.isInJumpBack) {
        if (!playbackRef.current.isProcessing){
          let aggregatedData: TelemetryDictionary = {};
          const totalLoops = Object.keys(influxData).length;
          processDataRef.current(aggregatedData, totalLoops, influxData as ProcessedData);
        }
      } else if (guiValues.current.isInJumpBack && !guiValues.current.pbisInPlayBack) {
        let aggregatedData: TelemetryDictionary = {};
    
    // Check if influxData is flattened array using type guard
    if (Array.isArray(influxData)) {
      // Process flattened data
      influxData.forEach(item => {
        if (item.name) {
          if (aggregatedData[item.name]) {
            if (item.cnvValue !== '') {
              aggregatedData[item.name].telemetry = item.cnvValue;
            }
          } else {
            aggregatedData[item.name] = {
              telemetry: item.cnvValue,
              mne: item.mne,
              limit: '',
              unit: '',
              spacecraft: '',
              live: 'rgb(68, 169, 241)',
            };
          }
        }
      });

      // Create nodes with the aggregated data
      const newNodes = createNodes(
        aggregatedData,
        pbTimeRef.current,
        pbLoopRef.current,
        groundList,
        locationData,
      );

      // Update nodes state
      setNodes(newNodes);
    }
      }
      else {
        // When not in playback mode, update nodes with live data
        const newNodes = createNodes(
          telemetryDictionary, 
          pbTimeRef.current,
          pbLoopRef.current,
          groundList,
          locationData,
        );
        setNodes(newNodes); 
      }
    }, [
      influxData,
      telemetryDictionary,
      guiValues,
      groundList,
      locationData,
    ]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};

  const activeAnn = (enabled: TLM, name: string) => {

    if (enabled === undefined || enabled === null || !enabled) {
      return 8
    }

    const edgeColor = enabled && enabled.telemetry && enabled.telemetry.includes(name) ? 'green' : 'white';
    const opa = edgeColor === 'green' ? 9 : 8;
    return opa
  }

  const initialEdges = [
  {id: 'TtoG', source: 'Tele Subsystem',sourceHandle: 'source-right-1', target: 'Ground Subsystem',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge1', source: 'Ground Subsystem',sourceHandle: 'source-left-1', target: 'MOC',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  
  {id: 'edge2', source: 'MOC',sourceHandle: 'source-right-1', target: 'Ground Subsystem',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'GtoT', source: 'Ground Subsystem',sourceHandle: 'source-right', target: 'Tele Subsystem',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  //{id: 'edge4', source: 'Tele Subsystem',sourceHandle: 'source-left-1', target: 'CDH Subsystem',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},
  {id: 'edge4', source: 'SP4T',sourceHandle: 'source-right', target: 'ScSketch',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},
  {id: 'edge5', source: 'ScSketch',sourceHandle: 'source-left-1', target: 'LNA',targetHandle:'target-right', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},

  {id: 'edge6', source: 'S-Band Transponder',sourceHandle: 'source-right-1', target: 'SP2T',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge7', source: 'SP2T',sourceHandle: 'source-left-1', target: 'S-Band Transponder',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge8', source: 'SPOC',sourceHandle: 'source-right-2', target: 'S-Band Transponder',targetHandle:'target-left-2', type:'smoothstep',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow,zIndex:0},
  {id: 'edge9', source: 'S-Band Transponder',sourceHandle: 'source-left-2', target: 'SPOC',targetHandle:'target-right-2', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge12', source: 'SPOC',sourceHandle: 'source-left-3', target: 'Supervisor',targetHandle:'target-right-3', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge13', source: 'Supervisor',sourceHandle: 'source-right-3', target: 'SPOC',targetHandle:'target-left-3', type:'smoothstep',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow,zIndex:0},
  
  {id: 'edge14', source: 'SP2T',sourceHandle: 'source-top', target: 'HPA',targetHandle:'target-bottom', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge15', source: 'HPA',sourceHandle: 'source-right', target: 'SP4T',targetHandle:'target-left-4', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge21', source: 'MGA Rx',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Mga'), data:{enabled:telemetryDictionary['Ant State'], name:'Mga'}},
  {id: 'edge22', source: 'LGA Rx 1',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Lga1'), data:{enabled:telemetryDictionary['Ant State'], name:'Lga1'}},
  {id: 'edge23', source: 'LGA Rx 2',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Lga2'), data:{enabled:telemetryDictionary['Ant State'], name:'Lga2'}},

  {id: 'edge24', source: 'LNA',sourceHandle: 'source-top', target: 'SP4T',targetHandle:'target-bottom', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge25', source: 'SP4T',sourceHandle: 'source-left-2', target: 'SP2T',targetHandle:'target-right', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

 ]; 

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height: height, width: width }}>
          <ReactFlow
          edges={initialEdges}
          onInit={onInit}
          edgeTypes={edgeTypes}
          nodes={nodes}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: option.X, y: option.Y, zoom: option.Zoom}}
          minZoom={0.1}
          fitView={true}
          />
        </div>

        <DrawerComponent isOpen={isDrawerOpen} onClose={onClose} keyData={keyMnePairs} />
  
    </ReactFlowProvider>
  );

}

export default ESCCommApp;
