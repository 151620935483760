import { css } from '@emotion/css';

export const FrliteBox = css`
width: 1100px;
height: 250px; 

  & > div {
    width:1100px;
    height: 180px;
    flex-direction: column;

    & > div:nth-of-type(4),
    & > div:nth-of-type(5) {
      width: 480px;
    }
    
    & > div:nth-of-type(1),
    & > div:nth-of-type(2),
    & > div:nth-of-type(3) {
      width: 600px;
    }
  }
`;


// Define a type for the class keys
export type ClassKey = 'FrliteBox'