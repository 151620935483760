import React from 'react';

type BarChartProps = {
  data: {
    x1: number;
    x2: number;
    x3: number;
    name: string;
  }
};

const BarChart: React.FC<BarChartProps> = ({ data}) => {

  const totalWidth = 1000;
  const totalHeight = 115;
  const maxValue = 125;
  const step = 2;
  const majorStep = 10;
  const scale = totalWidth / maxValue; // Scaling factor to fit in SVG

  const x1 = data.x1, x2 = data.x2, x3 = data.x3;

  const segments = [
    { start: 0, end: x1, color: 'rgb(115,119,247)' },
    { start: x1, end: x2, color: 'rgb(169,169,249)' },
    { start: x2, end: x3, color: 'rgb(225,225,247)' },
    { start: x3, end: maxValue, color: 'rgb(227,57,45)', pattern: false }
  ];

  const marks = [];
  for (let i = 0; i <= maxValue; i += step) {
    const x = totalWidth - (i * scale) + 50; // Calculate x from the right side
    const isMajorMark = i % majorStep === 0;
    const lineLength = isMajorMark ? 20 : 10; // longer line for major marks
    const label = isMajorMark ? i.toString() : ''; // label at major marks

    // Draw marks
    marks.push(
      <g key={i}>
        <line x1={x} y1={totalHeight + lineLength} x2={x} y2={totalHeight} stroke="#FFFFFF" />
        {isMajorMark && (
          <text x={x - (label.length * 3)} y={totalHeight + 35} fill="white" fontSize="12" fontFamily="Arial">{label}</text>
        )}
      </g>
    );
  }

  return (
    <div className="">

      {/* <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left"/>
      <Handle type="target" position={Position.Right} id="target-right"/>
      <Handle type="target" position={Position.Bottom} id="target-bottom"/>
      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left"/>
      <Handle type="source" position={Position.Right} id="source-right"/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/> */}

      <div>
        <defs>
          <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
            <path d="M-1,1 l2,-2
                     M0,4 l4,-4
                     M3,5 l2,-2" stroke="white" strokeWidth="1"/>
          </pattern>
        </defs>
        <svg xmlns="http://www.w3.org/2000/svg" width="1075" height="250" version="1.1" viewBox="0 0 1075 250">
          <rect x="50" y="15" width="1000" height="100" fill="rgb(38,38,38)" stroke="black" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10"/>
          {marks}
          {segments.map(segment => (
          <rect 
            key={segment.start}
            x={50 + (maxValue - segment.end) * scale} 
            y = "15"
            width={(segment.end - segment.start) * scale} 
            height="100" 
            fill={segment.pattern ? 'url(#diagonalHatch)' : segment.color} 
          />
        ))}
          <text x={50 + (maxValue) * scale - 5} y="10" fill="white" fontSize="12" fontFamily="Arial">0</text>
          <text x={50 + (maxValue - x1) * scale - 5} y="10" fill="white" fontSize="12" fontFamily="Arial">1</text>
          <text x={50 + (maxValue - x2) * scale - 5} y="10" fill="white" fontSize="12" fontFamily="Arial">2</text>
          <text x={50 + (maxValue - x3) * scale - 5} y="10" fill="white" fontSize="12" fontFamily="Arial">3</text>
          <text x="15" y="50" fill="white" fontSize="16" fontFamily="Arial" alignmentBaseline="central">{data.name}:</text>
          <rect x="50" y="48" width="1000" height="33" fill="gray" stroke="black" strokeWidth="1" strokeLinecap="round" strokeMiterlimit="10"/>
        </svg>
      </div>

    </div>
  );
};

export default BarChart;