// useSharedState.js
import { useRef, useState } from 'react';
import { defaultContext } from 'hooks/TimeRangeContext';
import { flattenInfluxData, processInfluxRawData } from 'utils/dataProcessFunc';
import { nameToMne, variableName } from 'utils/function';
import { ProcessedData, GuiValues, FlattenedItem } from 'utils/type';
import moment from 'moment';
import { fetchData } from './DataFetching';
import { getTemplateSrv } from '@grafana/runtime';

export const usePlayBack = () => {
  const [processedData, setProcessedData] = useState<ProcessedData| FlattenedItem[]>({});
  const guiValues = useRef<GuiValues>(defaultContext.guiValues.current);
  const source = getTemplateSrv().replace(`\${${variableName}}`);

  const stopPlayBack = () => {
    guiValues.current.pbisInPlayBack = false;
  };

  const playBlackFuunction = () => {
    if (guiValues.current.pbisInPlayBack || guiValues.current.isInJumpBack) {
      alert('Playback Or JumpBack is already in progress. Please stop the current service before starting a new one.');
      return;
    }

    guiValues.current.pbisInPlayBack = true;

    const mneList = Object.values(nameToMne);
    const mneFilter = mneList.map(mne => `r.mne == "${mne.toLowerCase()}"`).join(' or ');

    const start = moment.tz(new Date(guiValues.current.startDateTime), 'America/Los_Angeles').utc().format();
    const end = moment.tz(new Date(guiValues.current.endDateTime), 'America/Los_Angeles').utc().format();

    const aggTime = guiValues.current.pbaggTime;
    const aggFn = guiValues.current.pbaggFn.fn;

    const newSource = source ? source : 'esc_';

    console.log('start', start, 'end', end, 'aggTime', aggTime, 'aggFn', aggFn, 'newSource', newSource);

    const exQuery = `from(bucket: "telemetry")
      |> range(start: ${start}, stop: ${end})
      |> filter(fn: (r) =>
          r._measurement == "telemetry" and  
          r.facility == "${newSource}" and
          (${mneFilter}) 
      )
      |> filter(fn: (r) =>
          r._field == "cnv_value" or r._field == "t_pkt" or r._field == "t_insert"
      )
      |> aggregateWindow(every: ${aggTime}, fn: ${aggFn})
      |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")
    `;

    fetchData(exQuery, 'INFLUX').then(result => {
      if (result && result.data) {
        if (result.state === 'Error') {
          alert(`Error: ${result.error.message} (Status: ${result.error.status})`);
        }
        const queryData = result.data;
        console.log('queryData', queryData);
        const processInfluxData = processInfluxRawData(queryData);
        setProcessedData(processInfluxData);
      }
    });
  };

  // Assign functions to guiValues
  guiValues.current.pbplayBack = playBlackFuunction;
  guiValues.current.pbstopPlay = stopPlayBack;

  const stopJumpback = () => {
    guiValues.current.isInJumpBack = false;
  };

  const jumpbackFunction = () => {
    if (guiValues.current.pbisInPlayBack || guiValues.current.isInJumpBack) {
      alert('Playback Or JumpBack is already in progress. Please stop the current service before starting a new one.');
      return;
    }

    guiValues.current.isInJumpBack = true;

    const mneList = Object.values(nameToMne);
    const mneFilter = mneList.map(mne => `r.mne == "${mne.toLowerCase()}"`).join(' or ');

    const start = moment.tz(new Date(guiValues.current.jbstartTime), 'America/Los_Angeles').utc().format();

    // end is start + 30s
    const end = moment.tz(new Date(guiValues.current.jbstartTime), 'America/Los_Angeles').utc().add(30, 'seconds').format();
    
    const newSource = source ? source : 'esc_';

    console.log('start', start, 'end', end, 'newSource', newSource);

    const exQuery = `from(bucket: "telemetry")
      |> range(start: ${start}, stop: ${end})
      |> filter(fn: (r) =>
          r._measurement == "telemetry" and  
          r.facility == "${newSource}" and
          (${mneFilter}) 
      )
      |> first()
      |> filter(fn: (r) =>
          r._field == "cnv_value" or r._field == "t_pkt" or r._field == "t_insert"
      )
      |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")
    `;

    fetchData(exQuery, 'INFLUX').then(result => {
      if (result && result.data) {
        if (result.state === 'Error') {
          alert(`Error: ${result.error.message} (Status: ${result.error.status})`);
        }
        const queryData = result.data;
        console.log('queryDatajwkbgkwbgr', queryData);
        const processInfluxData = processInfluxRawData(queryData);
        console.log('processInfluxDaahbfkabdkta', processInfluxData);

        const flattenData = flattenInfluxData(processInfluxData);

        console.log('flattenData', flattenData);
        setProcessedData(flattenData);
      }
    });
  };

  // Assign functions to guiValues
  guiValues.current.jumpbackStart = jumpbackFunction;
  guiValues.current.exitjumpback = stopJumpback;

  return { processedData, setProcessedData, guiValues };
};


