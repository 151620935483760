import React, {useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { Field, LocationData, ProcessedData, TLM, TelemetryDictionary } from '../utils/type';
import ESCCommPanel from '../module/ESCCommPanel';
import CDH from '../module/CDH';
import ESCCommTB from './ESCCommTB';
import Ground from 'module/Ground';
import Tele from 'module/Tele';
import Antennas from './Antennas';
import MOC from 'module/MOC';
import CustomEdge from './CustomEdge';
import SBandTrans from 'components/SBandTrans';
import VaryAntennas from './VaryAntennas';
import SpacecraftModel from './SpacecraftModel';
import Chart from './PlotlyChart'
import BarChart from './Bar';
import { css } from '@emotion/css';
import VModeBox from './VModeBox';
import HTextBox from './HTextbox';
import ScSketch from './ScSketch';
import { useTimeRangeContext } from './TimeRangeContext';
import { useGroundData, useTelemetryData } from 'hooks/DataProcessing';
import PassIndicator from './PassIndicator';

const reactAppWrapper = css` 

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-escCommPanel{
  z-index: 5 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
`;

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  groundData: Field[];
  source: string;
  locationData: LocationData;
  influxData: ProcessedData;
  limitData: Field[];
  // ... other props if there are ay
};

const nodeTypes = {spacecraftModel: SpacecraftModel,passIndicator:PassIndicator,htextbox:HTextBox,scSketch:ScSketch,bar: BarChart,varyAntennas: VaryAntennas,vmodeBox:VModeBox,chart: Chart, ground: Ground,sbtrans:SBandTrans,escCommPanel: ESCCommPanel,antennas:Antennas, tele:Tele,cdh:CDH, escCommTB: ESCCommTB, moc:MOC}
const edgeTypes = {customEdge: CustomEdge}

const ESCCommApp: React.FC<AppProps> = ({dbData, groundData, source, locationData, width, height, influxData, limitData}) => {

  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  const { createTelemetryDictionary } = useTelemetryData(dbData, limitData);
  const { getGroundData } = useGroundData(groundData);
  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      //@ts-ignore
      reactFlowInstance.fitView();
    }
  }
  ,[width, height, reactFlowInstance]);

  const groundList = getGroundData();

  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'Panel', type: 'escCommPanel', position:{x:0, y:50}, data:{'source':source}},
    { id: 'SpacecraftModel', type: 'spacecraftModel',parentNode:'', position:{x:2550, y:1425}, data:{}},
    { id: 'Ground Subsystem', type: 'ground',parentNode:'Panel', position:{x:1500, y:1475}, data:{}},
    { id: 'MOC', type: 'moc',parentNode:'Panel', position:{x:50, y:1475}, data:{}},
    { id: 'CDH Subsystem', type: 'cdh',parentNode:'Panel', position:{x:50, y:50}, data:{}},
    { id: 'Tele Subsystem', type: 'tele',parentNode:'Panel', position:{x:1350, y:50}, data:{}},

    { id: 'Antennas', type: 'antennas',parentNode:'Ground Subsystem', position:{x:100, y:100}, data:{name:'', 'info':groundList, 'linfo':locationData}},

    { id: 'VR2', type: 'bar',parentNode:'MOC', position:{x:50, y:50}, data:{x1:17, x2:30, x3:100, name:"VR2"}},
    { id: 'VR3', type: 'bar',parentNode:'MOC', position:{x:50, y:250}, data:{x1:19, x2:32, x3:100, name:"VR3"}},
    { id: 'VR4', type: 'bar',parentNode:'MOC', position:{x:50, y:450}, data:{x1:23, x2:37, x3:100, name:"VR4"}},

    { id: 'ScSketch', type: 'scSketch',parentNode:'Tele Subsystem', position:{x:2360, y:150}, data:{name:'',select:telemetryDictionary['Ant State'], value:{'State':{'dbData':telemetryDictionary['Ant State'], 'fname':''}}}},
    { id: 'LNA', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1850, y:760}, data:{name:'Low Noise Amplifier', className:'lna',value:{'LGA1 5v':{'dbData':telemetryDictionary['LNA LGA1 5v'],'fname':''},'LGA2 5v':{'dbData':telemetryDictionary['LNA LGA2 5v'],'fname':''},'MGA 5v':{'dbData':telemetryDictionary['LNA MGA 5v'],'fname':''},'OCP BYP':{'dbData':telemetryDictionary['LNA OCP BYP'],'fname':''}}}},
    { id: 'SP4T', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1800, y:150}, data:{name:'Single Pole 4 Throw', value:{"Tx HGA Ind":{"dbData":telemetryDictionary['SP4T Tx HGA Ind'],'fname':''},"Tx MGA Ind":{"dbData":telemetryDictionary['SP4T Tx MGA Ind'],'fname':''},"Tx LGA1 Ind":{"dbData":telemetryDictionary['SP4T Tx LGA1 Ind'],'fname':''},"Tx LGA2 Ind":{"dbData":telemetryDictionary['SP4T Tx LGA2 Ind'],'fname':''},"Rx HGA Ind":{"dbData":telemetryDictionary['SP4T Rx HGA Ind'],'fname':''},"Rx MGA Ind":{"dbData":telemetryDictionary['SP4T Rx MGA Ind'],'fname':''},"Rx LGA1 Ind":{"dbData":telemetryDictionary['SP4T Rx LGA1 Ind'],'fname':''},"Rx LGA2 Ind":{"dbData":telemetryDictionary['SP4T Rx LGA2 Ind'],'fname':''}}}},
    { id: 'SP2T', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1275, y:750}, data:{name:'Single Pole 2 Throw',className:"Sp2TBox", value:{"Tx A Ind":{"dbData":telemetryDictionary['SP2T Tx A Ind'],'fname':''},"Rx A Ind":{"dbData":telemetryDictionary['SP2T Rx A Ind'],'fname':''},"Tx B Ind":{"dbData":telemetryDictionary['SP2T Tx B Ind'],'fname':''},"Rx B Ind":{"dbData":telemetryDictionary['SP2T Rx B Ind'],'fname':''}}}},
    { id: 'HPA', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1275, y:400}, data:{name:'High Power Amplifier',className:"HpaBox", value:{'HPA Enable':{"dbData":telemetryDictionary['HPA Enable'],'fname':''},'Err Count':{'dbData':telemetryDictionary['HPA Err Counter'],'fname':''},'Temp Raw':{'dbData':telemetryDictionary['HPA Temp Raw'],'fname':''}}}},
    { id: 'PassIndicaotr', type: 'passIndicator', parentNode: 'Tele Subsystem', position: { x: 50, y: 50 }, data: { sigDet: telemetryDictionary['Signal Detected'], sigPwr: telemetryDictionary['Loop Signal power'], loopStress: telemetryDictionary['Loop Stress'], carrierLock:telemetryDictionary['Rx loop lock'], detLock:telemetryDictionary['Detector Lock'] , bitLock: telemetryDictionary['Bit-Sync Lock'], cmdDecode: telemetryDictionary['Cnd DS'] } },

    { id: 'S-Band Transponder', type: 'sbtrans',parentNode:'Tele Subsystem', position:{x:25, y:300}, data:{}},
    { id: 'Receiver', type: 'escCommTB',parentNode:'S-Band Transponder', position:{x:625, y:50}, data:{name:'Receiver',value:{'AGC Level':{'dbData':telemetryDictionary['AGC Level'],'fname':''},'Carr Lock':{'dbData':telemetryDictionary['Carrier Lock'],'fname':''},'Carr Spe':{'dbData':telemetryDictionary['Carrier Spe'],'fname':''},'Carr Amp':{'dbData':telemetryDictionary['Carrier Amp'],'fname':''},'SubCarr Lock':{'dbData':telemetryDictionary['SubCarrier Lock'],'fname':''},'SubCarr Spe':{'dbData':telemetryDictionary['SubCarrier Spe'],'fname':''},'SubCarr Amp':{'dbData':telemetryDictionary['SubCarrier Amp'],'fname':''},"Sym Rate H":{"dbData":telemetryDictionary['Rx Sym Rate H'],'fname':''},"Sym Rate L":{"dbData":telemetryDictionary['Rx Sym Rate L'],'fname':''}, "NB Pwr":{"dbData":telemetryDictionary['Rim Frlite Rx Nb Pwr'],'fname':''}}},},
    { id: 'Transmitter', type: 'escCommTB',parentNode:'S-Band Transponder', position:{x:25, y:380}, data:{name:'Transmitter',className:"TransmitterBox",value:{'Ex Ena':{'dbData':telemetryDictionary['Tx Ex Ena'],'fname':''},'Rng Ena':{'dbData':telemetryDictionary['Tx Ex Rng Ena'],'fname':''},'SubC Freq Sel':{'dbData':telemetryDictionary['Tx Ex SubC Freq Sel'],'fname':''}, "Sym Rate":{"dbData":telemetryDictionary['Tx Ex Sym Rate'],'fname':''}, "Frames Sent":{"dbData":telemetryDictionary['Tx Frames Sent'],'fname':''}, "Watchdog Count":{"dbData":telemetryDictionary['Tx Watchdog count'],'fname':''}}}},
    { id: 'Frlite', type: 'escCommTB',parentNode:'S-Band Transponder', position:{x:25, y:50}, data:{name:'FR Lite',className:"FrliteBox",value:{'MSN Mode Custom':{'dbData':telemetryDictionary['Mission Mode Custom'],'fname':'Mission Mode Custom'},'MSN Mode Sel':{'dbData':telemetryDictionary['Mission Mode Sel'],'fname':'Mission Mode Sel'},'Cmds Received':{'dbData':telemetryDictionary['Radio Cmds Received'],'fname':''}}}},

    { id: 'SPOC', type: 'escCommTB',parentNode:'CDH Subsystem', position:{x:625, y:150}, data:{name:'SPOC',className:"SpocBox", value:{'A Voltage':{'dbData':telemetryDictionary['SPOC A Voltage'],'fname':''},'B Voltage':{'dbData':telemetryDictionary['SPOC B Voltage'],'fname':''},"Csac En":{"dbData":telemetryDictionary['Spoc Csac En'],'fname':''},"Csac Tod":{"dbData":telemetryDictionary['TIME_CSAC_Tod'],'fname':''},"Uptime":{"dbData":telemetryDictionary['Spoc Uptime'],'fname':''},"Max FSW Uptime":{"dbData":telemetryDictionary['Max FSW Uptime'],'fname':''},"Q7 Uptime":{"dbData":telemetryDictionary['Q7 Uptime'],'fname':''},"Q7 Boot Count":{'dbData':telemetryDictionary['Q7 Boot Count'],'fname':''},'Monitor State':{'dbData':telemetryDictionary['Spoc Monitor State'],'fname':''},'Monitor Tripped':{'dbData':telemetryDictionary['Spoc Monitor Tripped'],'fname':''}, 'Swap Inhibit':{'dbData':telemetryDictionary['Spoc Swap Inhibit'],'fname':''}, 'Switch Reason':{'dbData':telemetryDictionary['Spoc Switchover Reason'],'fname':''}, 'Beacon Flag':{'dbData':telemetryDictionary['Beacon Flag'],'fname':''}}}},
    { id: 'Supervisor', type: 'escCommTB',parentNode:'CDH Subsystem', position:{x:25, y:150}, data:{name:'Supervisor',className:"SpvrBox", value:{'Uptime':{'dbData':telemetryDictionary['Supvr Uptime'],'fname':'CPU running since boot'},'Boot Chip':{'dbData':telemetryDictionary['Supvr Boot Chip'],'fname':''},'Boot Index':{'dbData':telemetryDictionary['Supvr Boot Index'],'fname':''},'Boot Cnt':{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':''},'Pa3 LBoot Src':{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':'Last Boot Source'},'Pa3 NBoot Src':{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':'Next Boot Source'},'CD Time':{'dbData':telemetryDictionary['Supvr CD Time'],'fname':'Countdown Time'},'Monitor State':{'dbData':telemetryDictionary['Supvr Monitor State'],'fname':''},'Sync Curr Time':{'dbData':telemetryDictionary['Spvr Time Sync Curr Time'],'fname':''}}}},
 
    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:0, y:-105}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

]);

  const createNodes = useCallback((telemetryDictionary: TelemetryDictionary, locationDB: LocationData) => {
    return [
      { id: 'Panel', type: 'escCommPanel', position:{x:0, y:50}, data:{'source':source}},
      { id: 'SpacecraftModel', type: 'spacecraftModel',parentNode:'', position:{x:2550, y:1425}, data:{}},
      { id: 'Ground Subsystem', type: 'ground',parentNode:'Panel', position:{x:1500, y:1475}, data:{}},
      { id: 'MOC', type: 'moc',parentNode:'Panel', position:{x:50, y:1475}, data:{}},
      { id: 'CDH Subsystem', type: 'cdh',parentNode:'Panel', position:{x:50, y:50}, data:{}},
      { id: 'Tele Subsystem', type: 'tele',parentNode:'Panel', position:{x:1350, y:50}, data:{}},
  
      { id: 'Antennas', type: 'antennas',parentNode:'Ground Subsystem', position:{x:100, y:100}, data:{name:'', 'info':groundList, 'linfo':locationDB}},
  
      { id: 'VR2', type: 'bar',parentNode:'MOC', position:{x:50, y:50}, data:{x1:17, x2:30, x3:100, name:"VR2"}},
      { id: 'VR3', type: 'bar',parentNode:'MOC', position:{x:50, y:250}, data:{x1:19, x2:32, x3:100, name:"VR3"}},
      { id: 'VR4', type: 'bar',parentNode:'MOC', position:{x:50, y:450}, data:{x1:23, x2:37, x3:100, name:"VR4"}},
  
      { id: 'ScSketch', type: 'scSketch',parentNode:'Tele Subsystem', position:{x:2360, y:150}, data:{name:'',select:telemetryDictionary['Ant State'], value:{'State':{'dbData':telemetryDictionary['Ant State'], 'fname':''}}}},
      { id: 'LNA', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1850, y:760}, data:{name:'Low Noise Amplifier', className:'lna',value:{'LGA1 5v':{'dbData':telemetryDictionary['LNA LGA1 5v'],'fname':''},'LGA2 5v':{'dbData':telemetryDictionary['LNA LGA2 5v'],'fname':''},'MGA 5v':{'dbData':telemetryDictionary['LNA MGA 5v'],'fname':''},'OCP BYP':{'dbData':telemetryDictionary['LNA OCP BYP'],'fname':''}}}},
      { id: 'SP4T', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1800, y:150}, data:{name:'Single Pole 4 Throw', value:{"Tx HGA Ind":{"dbData":telemetryDictionary['SP4T Tx HGA Ind'],'fname':''},"Tx MGA Ind":{"dbData":telemetryDictionary['SP4T Tx MGA Ind'],'fname':''},"Tx LGA1 Ind":{"dbData":telemetryDictionary['SP4T Tx LGA1 Ind'],'fname':''},"Tx LGA2 Ind":{"dbData":telemetryDictionary['SP4T Tx LGA2 Ind'],'fname':''},"Rx HGA Ind":{"dbData":telemetryDictionary['SP4T Rx HGA Ind'],'fname':''},"Rx MGA Ind":{"dbData":telemetryDictionary['SP4T Rx MGA Ind'],'fname':''},"Rx LGA1 Ind":{"dbData":telemetryDictionary['SP4T Rx LGA1 Ind'],'fname':''},"Rx LGA2 Ind":{"dbData":telemetryDictionary['SP4T Rx LGA2 Ind'],'fname':''}}}},
      { id: 'SP2T', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1275, y:750}, data:{name:'Single Pole 2 Throw',className:"Sp2TBox", value:{"Tx A Ind":{"dbData":telemetryDictionary['SP2T Tx A Ind'],'fname':''},"Rx A Ind":{"dbData":telemetryDictionary['SP2T Rx A Ind'],'fname':''},"Tx B Ind":{"dbData":telemetryDictionary['SP2T Tx B Ind'],'fname':''},"Rx B Ind":{"dbData":telemetryDictionary['SP2T Rx B Ind'],'fname':''}}}},
      { id: 'HPA', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1275, y:400}, data:{name:'High Power Amplifier',className:"HpaBox", value:{'HPA Enable':{"dbData":telemetryDictionary['HPA Enable'],'fname':''},'Err Count':{'dbData':telemetryDictionary['HPA Err Counter'],'fname':''},'Temp Raw':{'dbData':telemetryDictionary['HPA Temp Raw'],'fname':''}}}},
      { id: 'PassIndicaotr', type: 'passIndicator', parentNode: 'Tele Subsystem', position: { x: 50, y: 50 }, data: { sigDet: telemetryDictionary['Signal Detected'], sigPwr: telemetryDictionary['Loop Signal power'], loopStress: telemetryDictionary['Loop Stress'], carrierLock:telemetryDictionary['Rx loop lock'], detLock:telemetryDictionary['Detector Lock'] , bitLock: telemetryDictionary['Bit-Sync Lock'], cmdDecode: telemetryDictionary['Cnd DS'] } },

      { id: 'S-Band Transponder', type: 'sbtrans',parentNode:'Tele Subsystem', position:{x:25, y:300}, data:{}},
      { id: 'Receiver', type: 'escCommTB',parentNode:'S-Band Transponder', position:{x:625, y:50}, data:{name:'Receiver',value:{'AGC Level':{'dbData':telemetryDictionary['AGC Level'],'fname':''},'Carr Lock':{'dbData':telemetryDictionary['Carrier Lock'],'fname':''},'Carr Spe':{'dbData':telemetryDictionary['Carrier Spe'],'fname':''},'Carr Amp':{'dbData':telemetryDictionary['Carrier Amp'],'fname':''},'SubCarr Lock':{'dbData':telemetryDictionary['SubCarrier Lock'],'fname':''},'SubCarr Spe':{'dbData':telemetryDictionary['SubCarrier Spe'],'fname':''},'SubCarr Amp':{'dbData':telemetryDictionary['SubCarrier Amp'],'fname':''},"Sym Rate H":{"dbData":telemetryDictionary['Rx Sym Rate H'],'fname':''},"Sym Rate L":{"dbData":telemetryDictionary['Rx Sym Rate L'],'fname':''}, "NB Pwr":{"dbData":telemetryDictionary['Rim Frlite Rx Nb Pwr'],'fname':''}}},},
      { id: 'Transmitter', type: 'escCommTB',parentNode:'S-Band Transponder', position:{x:25, y:380}, data:{name:'Transmitter',className:"TransmitterBox",value:{'Ex Ena':{'dbData':telemetryDictionary['Tx Ex Ena'],'fname':''},'Rng Ena':{'dbData':telemetryDictionary['Tx Ex Rng Ena'],'fname':''},'SubC Freq Sel':{'dbData':telemetryDictionary['Tx Ex SubC Freq Sel'],'fname':''}, "Sym Rate":{"dbData":telemetryDictionary['Tx Ex Sym Rate'],'fname':''}, "Frames Sent":{"dbData":telemetryDictionary['Tx Frames Sent'],'fname':''}, "Watchdog Count":{"dbData":telemetryDictionary['Tx Watchdog count'],'fname':''}}}},
      { id: 'Frlite', type: 'escCommTB',parentNode:'S-Band Transponder', position:{x:25, y:50}, data:{name:'FR Lite',className:"FrliteBox",value:{'MSN Mode Custom':{'dbData':telemetryDictionary['Mission Mode Custom'],'fname':'Mission Mode Custom'},'MSN Mode Sel':{'dbData':telemetryDictionary['Mission Mode Sel'],'fname':'Mission Mode Sel'},'Cmds Received':{'dbData':telemetryDictionary['Radio Cmds Received'],'fname':''}}}},
  
      { id: 'SPOC', type: 'escCommTB',parentNode:'CDH Subsystem', position:{x:625, y:150}, data:{name:'SPOC',className:"SpocBox", value:{'A Voltage':{'dbData':telemetryDictionary['SPOC A Voltage'],'fname':''},'B Voltage':{'dbData':telemetryDictionary['SPOC B Voltage'],'fname':''},"Csac En":{"dbData":telemetryDictionary['Spoc Csac En'],'fname':''},"Csac Tod":{"dbData":telemetryDictionary['TIME_CSAC_Tod'],'fname':''},"Uptime":{"dbData":telemetryDictionary['Spoc Uptime'],'fname':''},"Max FSW Uptime":{"dbData":telemetryDictionary['Max FSW Uptime'],'fname':''},"Q7 Uptime":{"dbData":telemetryDictionary['Q7 Uptime'],'fname':''},"Q7 Boot Count":{'dbData':telemetryDictionary['Q7 Boot Count'],'fname':''},'Monitor State':{'dbData':telemetryDictionary['Spoc Monitor State'],'fname':''},'Monitor Tripped':{'dbData':telemetryDictionary['Spoc Monitor Tripped'],'fname':''}, 'Swap Inhibit':{'dbData':telemetryDictionary['Spoc Swap Inhibit'],'fname':''}, 'Switch Reason':{'dbData':telemetryDictionary['Spoc Switchover Reason'],'fname':''}, 'Beacon Flag':{'dbData':telemetryDictionary['Beacon Flag'],'fname':''}}}},
      { id: 'Supervisor', type: 'escCommTB',parentNode:'CDH Subsystem', position:{x:25, y:150}, data:{name:'Supervisor',className:"SpvrBox", value:{'Uptime':{'dbData':telemetryDictionary['Supvr Uptime'],'fname':'CPU running since boot'},'Boot Chip':{'dbData':telemetryDictionary['Supvr Boot Chip'],'fname':''},'Boot Index':{'dbData':telemetryDictionary['Supvr Boot Index'],'fname':''},'Boot Cnt':{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':''},'Pa3 LBoot Src':{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':'Last Boot Source'},'Pa3 NBoot Src':{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':'Next Boot Source'},'CD Time':{'dbData':telemetryDictionary['Supvr CD Time'],'fname':'Countdown Time'},'Monitor State':{'dbData':telemetryDictionary['Supvr Monitor State'],'fname':''},'Sync Curr Time':{'dbData':telemetryDictionary['Spvr Time Sync Curr Time'],'fname':''}}}},
   
      { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:0, y:-105}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

  ]}, []); 

  useEffect(() => {
      if (!guiValues.current.pbisInPlayBack) {
        return;
      }
    
      let isCancelled = false;
    
      const processData = async () => {
        // Initialize an empty object to store telemetry data
        let aggregatedData: TelemetryDictionary = {};
        const totalLoops = Object.keys(influxData).length;
    
        for (let i = 0; i < totalLoops; i++) {
          // Check if the process should stop
          if (!guiValues.current.pbisInPlayBack || isCancelled) {
            break;
          }

          const key = Object.keys(influxData)[i];
          const selectedData = influxData[key];
            pbTimeRef.current = key;
            const newValue = (i + 1)/totalLoops;
            pbLoopRef.current = newValue;

    
          // Loop through each item in selectedData and update the aggregatedData object
          selectedData.forEach(item => {
            // If the key already exists, update the telemetry value
            if (aggregatedData[item.name]) {
              if (item.cnvValue !== '') {
                aggregatedData[item.name].telemetry = item.cnvValue;
              }
            } else {
              aggregatedData[item.name] = {
                telemetry: item.cnvValue,
                mne: item.mne,
                limit: "",
                unit: "",
                spacecraft: "",
                live: "rgb(68, 169, 241)",
              };
            }
          });
    
          // Create nodes from the aggregatedData object
          const newNodes = createNodes(aggregatedData, locationData);
          setNodes(newNodes);
    
          // Log the current loop progress
          //console.log(`${i + 1}/${totalLoops}`);
    
          // Wait for 2 seconds before processing the next item
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
        guiValues.current.pbstopPlay()
        //console.log('Playback completed');
      };
    
      processData();
    
      // Cleanup function to cancel the loop if pbisInPlayBack changes
      return () => {
        isCancelled = true; 
      };
    }, [guiValues, influxData]);
 
    useEffect(() => {
      //console.log('Playback started asfafa', guiValues.pbisInPlayBack);
      if (guiValues.current.pbisInPlayBack) {
        return;
      }

      const telemetryDictionary1 = createTelemetryDictionary();
    
      const newNodes = createNodes(telemetryDictionary1, locationData); // Function to create nodes from telemetryDictionary
      setNodes(newNodes);
    }, [ guiValues, dbData]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};

  const activeAnn = (enabled: TLM, name: string) => {

    if (enabled === undefined || enabled === null || !enabled) {
      return 8
    }

    const edgeColor = enabled && enabled.telemetry && enabled.telemetry.includes(name) ? 'green' : 'white';
    const opa = edgeColor === 'green' ? 9 : 8;
    return opa
  }

  const initialEdges = [
  {id: 'TtoG', source: 'Tele Subsystem',sourceHandle: 'source-right-1', target: 'Ground Subsystem',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge1', source: 'Ground Subsystem',sourceHandle: 'source-left-1', target: 'MOC',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  
  {id: 'edge2', source: 'MOC',sourceHandle: 'source-right-1', target: 'Ground Subsystem',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'GtoT', source: 'Ground Subsystem',sourceHandle: 'source-right', target: 'Tele Subsystem',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  //{id: 'edge4', source: 'Tele Subsystem',sourceHandle: 'source-left-1', target: 'CDH Subsystem',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},
  {id: 'edge4', source: 'SP4T',sourceHandle: 'source-right', target: 'ScSketch',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},
  {id: 'edge5', source: 'ScSketch',sourceHandle: 'source-left-1', target: 'LNA',targetHandle:'target-right', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:100},

  {id: 'edge6', source: 'S-Band Transponder',sourceHandle: 'source-right-1', target: 'SP2T',targetHandle:'target-left-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge7', source: 'SP2T',sourceHandle: 'source-left-1', target: 'S-Band Transponder',targetHandle:'target-right-1', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge8', source: 'SPOC',sourceHandle: 'source-right-2', target: 'S-Band Transponder',targetHandle:'target-left-2', type:'smoothstep',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow,zIndex:0},
  {id: 'edge9', source: 'S-Band Transponder',sourceHandle: 'source-left-2', target: 'SPOC',targetHandle:'target-right-2', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge12', source: 'SPOC',sourceHandle: 'source-left-3', target: 'Supervisor',targetHandle:'target-right-3', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge13', source: 'Supervisor',sourceHandle: 'source-right-3', target: 'SPOC',targetHandle:'target-left-3', type:'smoothstep',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow,zIndex:0},
  
  {id: 'edge14', source: 'SP2T',sourceHandle: 'source-top', target: 'HPA',targetHandle:'target-bottom', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},
  {id: 'edge15', source: 'HPA',sourceHandle: 'source-right', target: 'SP4T',targetHandle:'target-left-4', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge21', source: 'MGA Rx',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Mga'), data:{enabled:telemetryDictionary['Ant State'], name:'Mga'}},
  {id: 'edge22', source: 'LGA Rx 1',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Lga1'), data:{enabled:telemetryDictionary['Ant State'], name:'Lga1'}},
  {id: 'edge23', source: 'LGA Rx 2',sourceHandle: 'source-left', target: 'LNA',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:activeAnn(telemetryDictionary['Ant State'], 'Lga2'), data:{enabled:telemetryDictionary['Ant State'], name:'Lga2'}},

  {id: 'edge24', source: 'LNA',sourceHandle: 'source-top', target: 'SP4T',targetHandle:'target-bottom', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

  {id: 'edge25', source: 'SP4T',sourceHandle: 'source-left-2', target: 'SP2T',targetHandle:'target-right', type:'smoothstep',style: { stroke: 'white', strokeWidth:5,strokeDasharray:'20, 20'}, markerEnd: arrow,zIndex:0},

 ]; 

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height: height, width: width }}>
          <ReactFlow
          edges={initialEdges}
          onInit={onInit}
          edgeTypes={edgeTypes}
          nodes={nodes}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: option.X, y: option.Y, zoom: option.Zoom}}
          minZoom={0.1}
          fitView={true}
          />
        </div>
  
    </ReactFlowProvider>
  );

}

export default ESCCommApp;
