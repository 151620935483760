import React, { useState, useEffect, useRef } from "react";
import { css, cx } from '@emotion/css';
import { TLM } from "../utils/type";
import Timer from "./Timer";
import { useTimeRangeContext } from "hooks/TimeRangeContext";

interface VModeBoxProp {
  data: {
    name: string;
    VMode: TLM;
    playBackValue: {
      time: string;
      loop: number;
    }
  };
}

const containerStyle = css`
  border: 6px solid gray;
  background-color: rgb(38, 38, 38);
  width: 4250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 55px;
  padding: 20px;
`;

const innerContainerStyle = css`
  display: flex;
  flex-direction: row;
`;

const innerPValueStyle = css`
  margin: 0px 50px;
  cursor: pointer; /* Add pointer cursor to indicate clickable */
`;

const circleStyle = css`
  position: relative;
  width: 50px;
  height: 50px;
`;

const VModeBox: React.FC<VModeBoxProp> = ({ data }) => {
  const { oneWayLightTimeFieldValues, guiValues } = useTimeRangeContext();
  const [currentTime, setCurrentTime] = useState<string>("");
  const [countdown, setCountdown] = useState<number | null>(null);
  const [showTimer, setShowTimer] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleTimeClick = () => {
    if (showTimer) {return;}
    const inputSeconds = prompt("Enter the countdown duration in seconds:");
    if (inputSeconds) {
      const duration = parseInt(inputSeconds, 10);
      if (!isNaN(duration) && duration > 0) {
        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        setCountdown(duration);
        setShowTimer(true);

        // Set a new timeout dshj
        timeoutRef.current = setTimeout(() => {
          setShowTimer(false);
          setCountdown(null);
          timeoutRef.current = null;
        }, duration * 1000);
      }
    }
  };
  
  useEffect(() => {
    const updateTime = () => {
      const now = new Date();

      const year = now.getUTCFullYear();
      const start = new Date(Date.UTC(year, 0, 0));
      const diff = now.getTime() - start.getTime();
      const oneDay = 1000 * 60 * 60 * 24;
      const dayOfYear = Math.floor(diff / oneDay);

      const hours = String(now.getUTCHours()).padStart(2, '0');
      const minutes = String(now.getUTCMinutes()).padStart(2, '0');
      const seconds = String(now.getUTCSeconds()).padStart(2, '0');

      const formattedTime = `${year}/${String(dayOfYear).padStart(3, '0')} ${hours}:${minutes}:${seconds} Z`;
      setCurrentTime(formattedTime);
    };

    updateTime();
    const timerId = setInterval(updateTime, 1000);
    return () => {
      clearInterval(timerId);
      // Clear the timeout when component unmounts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const VModeStyle = css`
    margin: 0px 50px;
    padding: 0px;
    background-color: ${data.VMode.telemetry === 'BEACON_MODE' || data.VMode.telemetry === 'SAFE_MODE' ? 'Orange' : 'green'};
  `;

  const buttonFunction = () => {
    setShowTimer(false);
    setCountdown(null);
    // Clear the timeout when manually closing the timer
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const loopPercentage = data.playBackValue?.loop * 100;

  const calculateArc = (percentage: number) => {
    const angle = (percentage / 100) * 360;
    const radians = (angle - 90) * (Math.PI / 180);
    const x = 18 + 16 * Math.cos(radians);
    const y = 18 + 16 * Math.sin(radians);
    const largeArcFlag = percentage > 50 ? 1 : 0;
    return `M18 2 A16 16 0 ${largeArcFlag} 1 ${x} ${y} L18 18 Z`;
  };

  return (
    <div className={containerStyle}>
      <div className={innerContainerStyle}>
        <p>Current UTC Time:</p>
        <div style={{ position: 'relative' }}>
          <p className={innerPValueStyle} onClick={handleTimeClick}>
            {guiValues.current.pbisInPlayBack ? (
              <>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}>
                <span>{data.playBackValue.time}</span>
                <div className={circleStyle}>
                  <svg width="50" height="50" viewBox="0 0 36 36">
                    <path
                      d={calculateArc(loopPercentage)}
                      fill="green"
                    />
                    <circle cx="18" cy="18" r="16" fill="none" stroke="black" strokeWidth="2" />
                  </svg>
                </div>
                </div>
              </>
            ) : (
              currentTime
            )}
          </p>
          {showTimer && (
            <div style={{ position: 'absolute' }}>
              <Timer initialTime={countdown} isVisible={showTimer} />
              <button
                style={{
                  position: 'absolute',
                  top: -50,
                  right: 0,
                  fontSize: 30,
                  background: 'black',
                  width: 50,
                  height: 50,
                }} onClick={buttonFunction}>
                X
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={innerContainerStyle}>
        <p>Vehicle Mode:</p>
        <p className={cx(VModeStyle)}>{data && data.VMode && data.VMode.telemetry ? data.VMode.telemetry : ""}</p>
      </div>
      <div className={innerContainerStyle}>
        <p>Current 1-way Light Travel Delay:</p>
        <p className={innerPValueStyle}>{oneWayLightTimeFieldValues.toFixed(2)}s</p>
      </div>
    </div>
  );
};

export default VModeBox;
