import React from 'react';
import { Handle, Position } from 'reactflow';
import { css } from '@emotion/css';
import { getTemplateSrv } from '@grafana/runtime';
import { variableName } from 'utils/function';

interface ESCCommPanelProp {
  data: {
  };
}

const ESCCommPanel: React.FC<ESCCommPanelProp> = ({data}) => {

  const source = getTemplateSrv().replace(`\${${variableName}}`);
  
  let color;
  if (source.includes('escg')) {
    color = 'gold';
  } else if (source.includes('escb')) {
    color = 'rgb(13, 57, 152)';
  } else {
    color = 'gray';
  }

  const containerStyle = css`
    width: 4250px;
    height: 2250px;
    border: 6px solid ${color};
    background-color: rgb(38, 38, 38);
  `;

  return (
    <div className={containerStyle}>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity: 0}} />
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity: 0}} />
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity: 0}} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity: 0}} />
    </div>
  );
}

export default ESCCommPanel;
