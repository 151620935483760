import { css } from '@emotion/css';

export const lna = css`
  width: 400px;
`;

export const SpvrBox = css`
  width: 520px;
`;

export const SpocBox = css`
  width: 550px;
`;

export const TransmitterBox = css`
  width: 550px;
  `

export const Sp2TBox = css`
  width: 450px;
`;

export const HpaBox = css`
  width: 450px;np
`;

export const FrliteBox = css`
width: 550px;
`;

// Define a type for the class keys
export type ClassKey = 'lna' | 'SpvrBox' | 'SpocBox' | 'TransmitterBox' | 'Sp2TBox' | 'HpaBox' | 'FrliteBox';

