import { css } from '@emotion/css';
import React from 'react'
import { Handle, Position } from 'reactflow';

const Ground: React.FC = () => {

  const groundContainer = css`
  width: 1100px;
  height: 700px;
  border: 5px dashed rgb(33, 110, 224);
  position: relative;
  `

  return (
    <div className={groundContainer}>
      <span>Ground Station</span>

      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{top:300,opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right"/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{top:450,opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom"/>
      <Handle type="source" position={Position.Top} id="source-top-1" style={{left:1900,opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{top:450,opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right"/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>
    </div>
  )
}

export default Ground;
