// createNodes.ts

import { LocationData, TelemetryDictionary } from 'utils/type';
import { Node } from 'reactflow';

export const createNodes = (
  telemetryDictionary: TelemetryDictionary,
  pbTime: string,
  pbLoop: number,
  groundList: {SC: any;
    GS: any;
    AOS: string;
    LOS: string;
    Tr: any;},
  locationData: LocationData
) => {
  const nodes: Node[] = [
    { id: 'Panel', type: 'escCommPanel', position:{x:0, y:50}, data:{}},
    { id: 'SpacecraftModel', type: 'spacecraftModel',parentNode:'', position:{x:2750, y:1425}, data:{}},
    { id: 'Ground Subsystem', type: 'ground',parentNode:'Panel', position:{x:1700, y:1475}, data:{}},
    { id: 'MOC', type: 'moc',parentNode:'Panel', position:{x:50, y:1475}, data:{}},
    { id: 'CDH Subsystem', type: 'cdh',parentNode:'Panel', position:{x:50, y:50}, data:{}},
    { id: 'Tele Subsystem', type: 'tele',parentNode:'Panel', position:{x:1400, y:50}, data:{}},

    { id: 'Antennas', type: 'antennas',parentNode:'Ground Subsystem', position:{x:100, y:100}, data:{name:'', 'info':groundList, 'linfo':locationData}},

    { id: 'VR2', type: 'bar', parentNode: 'MOC', position: { x: -50, y: 175 }, data: { } },

    { id: 'ScSketch', type: 'scSketch',parentNode:'Tele Subsystem', position:{x:2400, y:150}, data:{name:'',select:telemetryDictionary['Ant State'], value:{'State':{'dbData':telemetryDictionary['Ant State'], 'fname':''}}}},
    { id: 'LNA', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1875, y:760}, data:{name:'Low Noise Amplifier', className:'lna',value:{'LGA1 5v':{'dbData':telemetryDictionary['LNA LGA1 5v'],'fname':''},'LGA2 5v':{'dbData':telemetryDictionary['LNA LGA2 5v'],'fname':''},'MGA 5v':{'dbData':telemetryDictionary['LNA MGA 5v'],'fname':''},'OCP BYP':{'dbData':telemetryDictionary['LNA OCP BYP'],'fname':''}}}},
    { id: 'SP4T', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1825, y:150}, data:{name:'Single Pole 4 Throw', value:{"Tx HGA Ind":{"dbData":telemetryDictionary['SP4T Tx HGA Ind'],'fname':''},"Tx MGA Ind":{"dbData":telemetryDictionary['SP4T Tx MGA Ind'],'fname':''},"Tx LGA1 Ind":{"dbData":telemetryDictionary['SP4T Tx LGA1 Ind'],'fname':''},"Tx LGA2 Ind":{"dbData":telemetryDictionary['SP4T Tx LGA2 Ind'],'fname':''},"Rx HGA Ind":{"dbData":telemetryDictionary['SP4T Rx HGA Ind'],'fname':''},"Rx MGA Ind":{"dbData":telemetryDictionary['SP4T Rx MGA Ind'],'fname':''},"Rx LGA1 Ind":{"dbData":telemetryDictionary['SP4T Rx LGA1 Ind'],'fname':''},"Rx LGA2 Ind":{"dbData":telemetryDictionary['SP4T Rx LGA2 Ind'],'fname':''}}}},
    { id: 'SP2T', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1275, y:750}, data:{name:'Single Pole 2 Throw',className:"Sp2TBox", value:{"Tx A Ind":{"dbData":telemetryDictionary['SP2T Tx A Ind'],'fname':''},"Rx A Ind":{"dbData":telemetryDictionary['SP2T Rx A Ind'],'fname':''},"Tx B Ind":{"dbData":telemetryDictionary['SP2T Tx B Ind'],'fname':''},"Rx B Ind":{"dbData":telemetryDictionary['SP2T Rx B Ind'],'fname':''}}}},
    { id: 'HPA', type: 'escCommTB',parentNode:'Tele Subsystem', position:{x:1275, y:400}, data:{name:'High Power Amplifier',className:"HpaBox", value:{'HPA Enable':{"dbData":telemetryDictionary['HPA Enable'],'fname':''},'Err Count':{'dbData':telemetryDictionary['HPA Err Counter'],'fname':''},'Temp Raw':{'dbData':telemetryDictionary['HPA Temp Raw'],'fname':''}}}},
    { id: 'PassIndicaotr', type: 'passIndicator', parentNode: 'Tele Subsystem', position: { x: 50, y: 50 }, data: { sigDet: telemetryDictionary['Signal Detected'], sigPwr: telemetryDictionary['Loop Signal power'], loopStress: telemetryDictionary['Loop Stress'], carrierLock:telemetryDictionary['Rx loop lock'], detLock:telemetryDictionary['Detector Lock'] , bitLock: telemetryDictionary['Bit-Sync Lock'], cmdDecode: telemetryDictionary['Cnd DS'] } },

    { id: 'S-Band Transponder', type: 'sbtrans',parentNode:'Tele Subsystem', position:{x:25, y:300}, data:{}},
    { id: 'Receiver', type: 'escCommTB',parentNode:'S-Band Transponder', position:{x:625, y:50}, data:{name:'Receiver',value:{'AGC Level':{'dbData':telemetryDictionary['AGC Level'],'fname':''},'Carr Lock':{'dbData':telemetryDictionary['Carrier Lock'],'fname':''},'Carr Spe':{'dbData':telemetryDictionary['Carrier Spe'],'fname':''},'Carr Amp':{'dbData':telemetryDictionary['Carrier Amp'],'fname':''},'SubCarr Lock':{'dbData':telemetryDictionary['SubCarrier Lock'],'fname':''},'SubCarr Spe':{'dbData':telemetryDictionary['SubCarrier Spe'],'fname':''},'SubCarr Amp':{'dbData':telemetryDictionary['SubCarrier Amp'],'fname':''},"Sym Rate H":{"dbData":telemetryDictionary['Rx Sym Rate H'],'fname':''},"Sym Rate L":{"dbData":telemetryDictionary['Rx Sym Rate L'],'fname':''}, "NB Pwr":{"dbData":telemetryDictionary['Rim Frlite Rx Nb Pwr'],'fname':''}}},},
    { id: 'Transmitter', type: 'escCommTB',parentNode:'S-Band Transponder', position:{x:25, y:380}, data:{name:'Transmitter',className:"TransmitterBox",value:{'Ex Ena':{'dbData':telemetryDictionary['Tx Ex Ena'],'fname':''},'Rng Ena':{'dbData':telemetryDictionary['Tx Ex Rng Ena'],'fname':''},'SubC Freq Sel':{'dbData':telemetryDictionary['Tx Ex SubC Freq Sel'],'fname':''}, "Sym Rate":{"dbData":telemetryDictionary['Tx Ex Sym Rate'],'fname':''}, "Frames Sent":{"dbData":telemetryDictionary['Tx Frames Sent'],'fname':''}, "Watchdog Count":{"dbData":telemetryDictionary['Tx Watchdog count'],'fname':''}}}},
    { id: 'Frlite', type: 'escCommTB',parentNode:'S-Band Transponder', position:{x:25, y:50}, data:{name:'FR Lite',className:"FrliteBox",value:{'MSN Mode Custom':{'dbData':telemetryDictionary['Mission Mode Custom'],'fname':'Mission Mode Custom'},'MSN Mode Sel':{'dbData':telemetryDictionary['Mission Mode Sel'],'fname':'Mission Mode Sel'},'Cmds Received':{'dbData':telemetryDictionary['Radio Cmds Received'],'fname':''}}}},

    { id: 'SPOC', type: 'escCommTB',parentNode:'CDH Subsystem', position:{x:625, y:150}, data:{name:'SPOC',className:"SpocBox", value:{'A Voltage':{'dbData':telemetryDictionary['SPOC A Voltage'],'fname':''},'B Voltage':{'dbData':telemetryDictionary['SPOC B Voltage'],'fname':''},"Csac En":{"dbData":telemetryDictionary['Spoc Csac En'],'fname':''},"Csac Tod":{"dbData":telemetryDictionary['TIME_CSAC_Tod'],'fname':''},"Uptime":{"dbData":telemetryDictionary['Spoc Uptime'],'fname':''},"Max FSW Uptime":{"dbData":telemetryDictionary['Max FSW Uptime'],'fname':''},"Q7 Uptime":{"dbData":telemetryDictionary['Q7 Uptime'],'fname':''},"Q7 Boot Count":{'dbData':telemetryDictionary['Q7 Boot Count'],'fname':''},'Monitor State':{'dbData':telemetryDictionary['Spoc Monitor State'],'fname':''},'Monitor Tripped':{'dbData':telemetryDictionary['Spoc Monitor Tripped'],'fname':''}, 'Swap Inhibit':{'dbData':telemetryDictionary['Spoc Swap Inhibit'],'fname':''}, 'Switch Reason':{'dbData':telemetryDictionary['Spoc Switchover Reason'],'fname':''}, 'Beacon Flag':{'dbData':telemetryDictionary['Beacon Flag'],'fname':''}}}},
    { id: 'Supervisor', type: 'escCommTB',parentNode:'CDH Subsystem', position:{x:25, y:150}, data:{name:'Supervisor',className:"SpvrBox", value:{'Uptime':{'dbData':telemetryDictionary['Supvr Uptime'],'fname':'CPU running since boot'},'Boot Chip':{'dbData':telemetryDictionary['Supvr Boot Chip'],'fname':''},'Boot Index':{'dbData':telemetryDictionary['Supvr Boot Index'],'fname':''},'Boot Cnt':{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':''},'Pa3 LBoot Src':{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':'Last Boot Source'},'Pa3 NBoot Src':{'dbData':telemetryDictionary['Supvr Boot Ctn'],'fname':'Next Boot Source'},'CD Time':{'dbData':telemetryDictionary['Supvr CD Time'],'fname':'Countdown Time'},'Monitor State':{'dbData':telemetryDictionary['Supvr Monitor State'],'fname':''},'Sync Curr Time':{'dbData':telemetryDictionary['Spvr Time Sync Curr Time'],'fname':''}}}},
 
    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:0, y:-105}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTime, loop: pbLoop}}},

  ];

  return nodes;
};
