import React from 'react'
import { Handle, Position } from 'reactflow';
import { InnerData, TLM } from '../utils/type';
import { css } from '@emotion/css';

interface VaryAntennasProps {
  data: {
    name: string;
    value: InnerData;
    textbox_item_class: string;
    textbox_container_class: string;
    id: string;
    select: TLM;
    annName: string;
  };
}

const VaryAntennas: React.FC<VaryAntennasProps> = ({data}) => {

  const getColor = (select: TLM) => {
    if (select && select.telemetry){
      if (select.telemetry.toLowerCase().includes(data.annName.toLowerCase())) {
        return "green"; // Green for the selected and ON disk
      }
      else{
        return "black"
      }
    }else{
      return "black"
    }
  }

  const varyAnnStyle = css`
  width: 200px;
  border: 5px solid rgb(104, 176, 253);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getColor(data.select)}; 

`;

  return (
    <div className={varyAnnStyle}>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{opacity:0, top:30}}/>

      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{opacity:0, top:50}}/>

      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <div>{data.name}</div>
    </div>
  )
}

export default VaryAntennas;
