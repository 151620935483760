import React, { createContext, useState, useContext, ReactNode } from 'react';
import { ProcessedData } from '../utils/type';

interface DataContextType {
  processedData: ProcessedData;
  setProcessedData: React.Dispatch<React.SetStateAction<ProcessedData>>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [processedData, setProcessedData] = useState<ProcessedData>({});

  return (
    <DataContext.Provider value={{ processedData, setProcessedData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};

