import React from 'react'
import { Handle, Position } from 'reactflow';
import { css } from '@emotion/css';

const Tele: React.FC = () => {

  const teleContainer = css`
  width: 2775px;
  height: 1200px;
  border: 5px dashed rgb(33, 110, 224);
  position: relative;
  `

  return (
    <div className={teleContainer}>
      <span>Telecommunication</span>

      <Handle type="target" position={Position.Top} id="target-top"/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{top:395}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{top:595}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom"/>
      <Handle type="source" position={Position.Top} id="source-top"/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{top:595}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{top:395}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom"/>
    </div>
  )
}

export default Tele;
