import { createContext, useContext } from 'react';
import { timeContextProps } from 'utils/type';

export const defaultContext: timeContextProps = {
  // @ts-ignore
  timeRange: { from: new Date(), to: new Date() },
  guiValues: {
    current: {
      aggTime: '1s',
      aggFn: { fn: 'last' },
      Agg: false,
      Last: false,
      lastCount: 50,
  
      startDateTime: '2024-11-18 10:10:00',
      endDateTime: '2024-11-18 16:15:00',
      pbaggTime: '10m',
      pbaggFn: { fn: 'last'},
      pbisInPlayBack: false,
      pbstopPlay: () => {},
      pbplayBack: () => {},
      jbstartTime: '2024-11-18 11:10:00',
      isInJumpBack: false, 
      jumpbackStart: () => {},
      exitjumpback: () => {},
    }
  },
  gapsData: [
    { name: "", type: "", values: []}
  ],
  oneWayLightTimeFieldValues: 0
};

export const TimeRangeContext = createContext<timeContextProps>(defaultContext);

export const useTimeRangeContext = () => useContext(TimeRangeContext);
