import * as THREE from 'three'
import React, { useRef,useEffect } from 'react'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OrbitControls } from 'three-stdlib';


const ThreeScene = () => {
    const width = 1500;
  const height = 900;
  const mountRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mountRef.current) return;
      // Scene setup
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(40, width / height, 0.1, 200);
      const renderer = new THREE.WebGLRenderer({ alpha: true });
      renderer.setSize(width, height);
      renderer.setClearColor(0x000000, 0)
      mountRef.current.appendChild(renderer.domElement);

          // OrbitConrols
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation is enabled
    // controls.dampingFactor = 0.25;
    // controls.screenSpacePanning = false; 

      // Loaders
    const mtlLoader = new MTLLoader();
    mtlLoader.load("public/plugins/ssl-esccomm-panel/img/components/Spacecraft.mtl", (materials) => {
      materials.preload();
      

      // OBJ Loader
      const loader = new OBJLoader();
    loader.setMaterials(materials);
      loader.load(
            "public/plugins/ssl-esccomm-panel/img/components/Spacecraft.obj", // Path to OBJ file
          (object) => {
            console.log("load finished")
            object.scale.set(0.1, 0.1, 0.1);
              scene.add(object);

              // Adjust camera position
                const box = new THREE.Box3().setFromObject(object);
                const size = box.getSize(new THREE.Vector3()).length();
                
                const center = box.getCenter(new THREE.Vector3());
                //object.position.y = object.position.y + center.y /3;
                camera.updateProjectionMatrix();

                // Adjust camera position
                camera.position.set(center.x + size/5, center.y + size/5 , center.z + size * 0.8);
                camera.lookAt(center);

                controls.update();

                // Lighting
                const ambientLight = new THREE.AmbientLight(0x404040);
                scene.add(ambientLight);

                const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
                directionalLight.position.set(5, 5, 5);
                scene.add(directionalLight);

                const axesHelper = new THREE.AxesHelper(0.25);
                scene.add(axesHelper);
                
                      // Animation loop
                const animate = () => {
                    requestAnimationFrame(animate);
                    renderer.render(scene, camera);
                };

                animate();
          },
          (xhr) => {
              //console.log((xhr.loaded / xhr.total * 100) + '% loaded');
          },
          (error) => {
              console.error('An error happened during loading: ', error);
          }
      );
        })
  }, []);

  return <div ref={mountRef} />;
};

export default ThreeScene;
