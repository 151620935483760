import { useRef } from "react";
import { nameToMne, source } from "../utils/function";
import moment from 'moment-timezone';
import { fetchData } from "./DataFetching";
import { processInfluxPlayBackData } from "./DataProcessing";
import { useData } from "./DataContext";

export const usePlayBack = () => {
  const { setProcessedData } = useData();  // Use the context to get setProcessedData

const stopPlayBack = () => {
    guiValues.current['pbisInPlayBack'] = false;
}

const playBlackFuunction = () => {

    if (guiValues.current.pbisInPlayBack){
      alert('Playback is already in progress. Please stop the current playback before starting a new one.');
      return;
    }

    guiValues.current.pbisInPlayBack = true;

    const mneList = Object.values(nameToMne);
    
    const mneFilter = mneList.map(mne => `r.mne == "${mne.toLowerCase()}"`).join(' or ');

    // const start = new Date(guiValues.startDateTime).toISOString();
    // const end = new Date(guiValues.endDateTime).toISOString();
    const start = moment.tz(new Date(guiValues.current.startDateTime), 'America/Los_Angeles').utc().format();
    const end = moment.tz(new Date(guiValues.current.endDateTime), 'America/Los_Angeles').utc().format();

    const aggTime = guiValues.current.pbaggTime;
    const aggFn = guiValues.current.pbaggFn.fn;

    //console.log('d', start, end, aggTime, aggFn, locationFilter, source)

    let newSource;
    if (source === "glide_int") {
      newSource = 'glide_int';
    }else if (source === "glide_swtb") {
      newSource = 'glide_swtb';
    }else {
      newSource = 'glide_';
    }

    const exQuery = `from(bucket: "telemetry")
    |> range(start: ${start}, stop: ${end})
    |> filter(fn: (r) =>
        r._measurement == "telemetry" and  
        r.facility == "${newSource}" and
        (${mneFilter}) 
    )
    |> filter(fn: (r) =>
        r._field == "cnv_value" or r._field == "t_pkt" or r._field == "t_insert"
    )
    |> aggregateWindow(every: ${aggTime}, fn: ${aggFn})
    |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")
  `;

    fetchData(exQuery, 'INFLUX').then(result => {
      if (result && result.data) { 
        if (result.state === 'Error') {
          alert(`Error: ${result.error.message} (Status: ${result.error.status})`);
        }
        const queryData = result.data; 
        const processInfluxData = processInfluxPlayBackData(queryData);
        setProcessedData(processInfluxData);
      }
    });
  } 

  const guiValues = useRef({
    aggTime: '1s',
    aggFn: { fn: 'last' },
    Agg: false,
    Last: false, 
    lastCount: 50,
 
    startDateTime: '2024-10-04 11:18:00',
    endDateTime: '2024-10-04 11:20:00',
    pbaggTime: '5s',
    pbaggFn: { fn: 'mean' },
    pbplayBack: playBlackFuunction,
    pbstopPlay: stopPlayBack,
    pbisInPlayBack: false,
  });


  return { playBlackFuunction, stopPlayBack, guiValues };
}
  
